import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import history from '../../services/history';

// react plugin for creating charts
import ChartistGraph from 'react-chartist';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons
import Store from '@material-ui/icons/Store';
// import Warning from "@material-ui/icons/Warning";
import Chartist from 'chartist';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Danger from 'components/Typography/Danger.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import ctPointLabels from 'chartist-plugin-pointlabels';
import legend from 'chartist-plugin-legend';
import { API } from '../../config/api';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

//import ctBarLabels from 'chartist-plugin-barlabels';

// import { bugs, website, server } from "variables/general.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from 'variables/charts.js';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import { Load } from './styles.js';
const useStyles = makeStyles(styles);

function formatDecimal(numero) {
  return numero
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+\,)/g, '$1.');
}

export default function Dashboard() {
  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  const perfil = sessionStorage.getItem('perfil');
  const id_erp = sessionStorage.getItem('id_erp');

  const [vendas, setVendas] = useState('');
  const [totFaturado, setTotFaturado] = useState('');
  const [valor, setValor] = useState('');
  const [vendasAno, setVendasAno] = useState([]);
  const [vendasMes, setVendasMes] = useState([]);
  const [vendasSemanal, setVendasSemanal] = useState([]);
  const [loading, setLogin] = useState(true);
  const [loadingValor, setLoadingValor] = useState(true);
  const [meta, setMeta] = useState(0);


  if (perfil === 'funcionario') {
    toast.error('Usuário em permissão de acesso');
    history.push('/clientes');
  }

  useEffect(() => {
    async function getmeta() {
      try {
        const response = await axios.get(
          `${API.getMetas}?email=${email}&id_erp=${id_erp}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        console.log(response);
        setMeta(response.data.data);

      } catch (err) {
       // toast.error('Erro ao carregar as metas para o usuário');
      }
    }
    async function vendasAno() {
      try {
        const response = await axios.get(
          `${API.totalfaturado}?email=${email}&periodo=12m`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        var vendasano = [];
        response.data.data.map(item => {
          var valor = item.VALOR;

          vendasano.push({ CAPTION: item.CAPTION, VALOR: valor });
        });
        setVendasAno(vendasano);
      } catch (err) {
        toast.error('Erro ao listar vendas do ano');
      }
    }
    async function totalFaturado() {
      try {
        const response = await axios.get(
          `${API.totalfaturado}?email=${email}&periodo=tot`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        setTotFaturado(
          response && response.data.data[0].VALOR_TOTAL_FATURAMENTO != null
            ? response.data.data[0].VALOR_TOTAL_FATURAMENTO
            : 0,
        );
      } catch (err) {
        toast.error('Erro ao listar total faturado');
      }
    }
    async function totalVendasMes() {
      try {
        const response = await axios.get(
          `${API.totalfaturado}?email=${email}&periodo=1m`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        setVendasMes(
          response && response.data.data !== null ? response.data.data : '',
        );
      } catch (err) {
        toast.error('Erro ao listar vendas do ano');
      }
    }
    async function vendaSemanal() {
      try {
        const response = await axios.get(
          `${API.totalfaturado}?email=${email}&periodo=8s`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        setVendasSemanal(response.data.data ? response.data.data : '');
      } catch (err) {
        toast.error('Erro ao listar vendas da semana');
      }
    }
    async function qntVendas() {
      try {
        const response = await axios.get(`${API.qtdvendasmes}?email=${email}`, {
          headers: {
            'x-access-token': token,
          },
        });
        setVendas(
          response && response.data.data[0].QUANTIDADE_VENDAS != null
            ? response.data.data[0].QUANTIDADE_VENDAS
            : 0,
        );

        setLogin(false);
      } catch (err) {
        toast.error('Erro ao listar vendas');
      }
    }
    async function vendasMes() {
      try {
        const response = await axios.get(`${API.vlvendasmes}?email=${email}`, {
          headers: {
            'x-access-token': token,
          },
        });
        setValor(
          response && response.data.data[0].VALOR_TOTAL_VENDAS !== null
            ? response.data.data[0].VALOR_TOTAL_VENDAS
            : 0,
        );
        setLoadingValor(false);
      } catch (err) {
        toast.error('Erro ao listar valor das vendas');
      }
    }
    getmeta();

    qntVendas();
    vendasMes();
    vendasAno();
    totalVendasMes();
    vendaSemanal();
    totalFaturado();
  }, []);
  const percentage = meta > 0?((totFaturado/meta)*100).toFixed(2): 0;

  const classes = useStyles();
  // VEndas ano
  let arrayAnos = [];
  let arrayValores = [];
  vendasAno.map(item => {
    arrayAnos.push(item.CAPTION);
    arrayValores.push(item.VALOR);
  });
  let maxAno = '';
  if (arrayValores && arrayValores.length > 0) {
    let maxT = arrayValores.reduce((a, b) => {
      return Math.max(a, b) + 20000;
    });
    maxAno = maxT;
  }
  let a = {
    data: {
      labels: arrayAnos,
      series: [arrayValores],
    },
    options: {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0,
      }),
      plugins: [
        Chartist.plugins.ctPointLabels({
          textAnchor: 'right',
          labelInterpolationFnc: value => {
            if (typeof value === 'undefined') return '0';
            else return 'R$ ' + formatDecimal(value);
          },
        }),
      ],
      low: 0,
      high: maxAno, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 30,
      },
    },
  };
  // Vendas mês
  let arrayMeses = [];
  let arrayValoresMes = [];
  vendasMes.map(item => {
    arrayMeses.push(item.CAPTION);
    arrayValoresMes.push(item.VALOR);
  });
  let maxMes = '';
  if (arrayValoresMes && arrayValoresMes.length > 0) {
    let maxT = arrayValoresMes.reduce((a, b) => {
      return Math.max(a, b) + 20000;
    });
    maxMes = maxT;
  }
  let m = {
    data: {
      labels: arrayMeses,
      series: [arrayValoresMes],
    },
    options: {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 2,
      }),
      stackBars: true,

      plugins: [legend()],
      horizontalBars: false,
      plugins: [
        Chartist.plugins.ctPointLabels({
          labelClass: 'ct-label',
          labelOffset: {
            x: 0,
            y: -50,
          },
          textAnchor: 'middle',
          labelInterpolationFnc: value => {
            if (typeof value === 'undefined') return '0';
            else return 'R$ ' + formatDecimal(value);
          },
        }),
      ],
      low: 0,
      high: maxMes, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 30,
      },
    },
  };
  // Venda Semanal
  let arraySemana = [];
  let arrayValoresSemana = [];

  vendasSemanal.map(item => {
    arraySemana.push(item.CAPTION);
    arrayValoresSemana.push(item.VALOR);
  });
  let max = '';
  if (arrayValoresSemana.length > 0) {
    let maxT = arrayValoresSemana.reduce((a, b) => {
      return Math.max(a, b) + 10000;
    });
    max = maxT;
  }
  let s = {
    data: {
      labels: arraySemana,
      series: [arrayValoresSemana],
    },
    options: {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 200000,
      }),
      plugins: [
        Chartist.plugins.ctPointLabels({
          labelClass: 'ct-label',
          labelOffset: {
            x: 0,
            y: -20,
          },
          textAnchor: 'middle',
          labelInterpolationFnc: value => {
            if (typeof value === 'undefined') return '0';
            else return 'R$ ' + formatDecimal(value);
          },
        }),
      ],
      low: 0,
      high: max, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 30,
      },
    },
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>trending_up</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Vendas realizadas no mês</p>
              <h3
                className={classes.cardTitle}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {loading ? <Load cor={'#ffa726'} /> : `${vendas} vendas`}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger></Danger>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Total</p>
              <h3 className={classes.cardTitle}>
                {loadingValor ? (
                  <Load cor={'#66bb6a'} />
                ) : (
                  valor.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                )}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger></Danger>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card style={{ zindex: 2 }}>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Icon>attach_money</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Total Faturado</p>
              <h3 className={classes.cardTitle}>
                {loadingValor ? (
                  <Load cor={'#66bb6a'} />
                ) : (
                  totFaturado.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                )}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger></Danger>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>attach_money</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Comissão no período</p>
              <h3 className={classes.cardTitle}>R$ 2.054,40</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                Módulo desabilitado!
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
      </GridContainer>
      <GridContainer>
      <GridItem xs={12} sm={12} md={3}>
          <Card chart>
            <CardHeader style={{ width: 160, height: '100%' }} color="success">

              <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                styles={{

                  root: {},
                  trail: {
                    strokeLinecap: 'butt',
                    transform: 'rotate(-135deg)',
                    transformOrigin: 'center center',
                  },
                  path: {
                    strokeLinecap: 'butt',
                    transform: 'rotate(-135deg)',
                    transformOrigin: 'center center',
                  },
                  text: {
                    fill: '#ffffff',
                    fontSize: '24px',
                  },
                  background: {
                    fill: '#3e98c7',
                  },
                }}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Meta de vendas: {meta.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
              }</h4>
              <h4 className={classes.cardTitle}>Meta alcançada: {totFaturado.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}</h4>

            </CardBody>

          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={9}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={s.data}
                max={max}
                type="Line"
                options={s.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Vendas na semana</h4>
            </CardBody>
            {/* <CardFooter chart>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                Módulo desabilitado!
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={m.data}
                type="Bar"
                options={m.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Vendas por mês</h4>
              <p className={classes.cardCategory}>
                Performance de vendas no período
              </p>
            </CardBody>
            {/* <CardFooter chart>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                Módulo desabilitado!
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={a.data}
                type="Line"
                options={a.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Vendas por ano</h4>
              <p className={classes.cardCategory}>
                Performance de vendas por ano
              </p>
            </CardBody>
            {/* <CardFooter chart>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                Módulo desabilitado!
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
      </GridContainer>
      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title="Agenda"
            headerColor="primary"
            tabs={[
              {
                tabName: "Visitas agendadas",
                tabIcon: Code,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0]}
                    tasksIndexes={[0, 1]}
                    tasks={website}
                  />
                )
              },
              {
                tabName: "Visitas concluídas",
                tabIcon: Cloud,
                tabContent: (
                  <Tasks
                    checkedIndexes={[1]}
                    tasksIndexes={[0, 1, 2]}
                    tasks={server}
                  />
                )
              }
            ]}
          />
        </GridItem>
      </GridContainer> */}
    </div>
  );
}
