import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import Autocomplete from 'react-autocomplete';
import { toast } from 'react-toastify';
import { Form, ButtonStyled } from './styles';
import { API } from '../../../config/api';
import { BottomModal } from 'react-spring-modal';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import Select from 'react-select';

import 'react-spring-modal/dist/index.css';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '80%',
    maxHeight: '80%',
    overflow: 'scroll',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  button: {
    border: 0,
    borderRadius: '20px',
    backgroundColor: '#00c156',
    color: '#fff',
    padding: '5px',
    cursor: 'pointer',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: '15px',
  },
}));

export default function ModalCreate() {
  // Token
  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  const [autoTransp, setautoTransp] = useState([]);
  const [autoTranspRedespacho, setautoTranspRedespacho] = useState('');
  const [nomeTransp, setNomeTransp] = useState('');
  const [nomeTranspRedespacho, setNomeTranspRedespacho] = useState('');
  const [idTransp, setIdTransp] = useState('');
  const [idTranspRedespacho, setIdTranspRedespacho] = useState('');
  const [files_saved, setFiles_saved] = useState('');
  const [przPagamento, setprzPagamento] = useState([]);

  const buttonRef = useRef(null);


  const [fields, setfields] = useState({
    ativa: true,
    bloqueada: false,
    tipo: 'JURIDICA',
    nome: null,
    apelido: null,
    cnpj: null,
    inscricaoEstadual: null,
    cpf: null,
    rg: null,
    enderecoLogradouro: null,
    enderecoNumero: null,
    enderecoComplemento: null,
    enderecoBairro: null,
    enderecoCidade: null,
    enderecoEstado: null,
    enderecoPais: 'Brasil',
    enderecoCep: null,
    observacoes: null,
    vendedorPadrao: {
      id: null,
      nome: null,
    },
  });

  // Modal
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [required, setRequired] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // react-hooks-form
  const { register, handleSubmit } = useForm();

  // Tipo Required
  const [tipoRequired, setTipoRequired] = useState(true);

  const handleRequiretipo = e => {
    if (e === "FISICA") {
      setTipoRequired(false);
      setRequired(false);

      setfields({
        ...fields,
        'tipo': 'FISICA'
      });

    } else {
      setTipoRequired(true);
      setRequired("required");
      setfields({
        ...fields,
        'tipo': 'JURIDICA'
      });
    }
  };

  function handleChange(evt) {
    const value = evt.target.value.replaceAll('_', '');

    setfields({
      ...fields,
      [evt.target.id]: value,
    });
  }

  const loadClients = async () => {
    try {
      var toastId = null;
      if (toastId === null) {
        toastId = toast.info('Carregando dados do sistema, aguarde.', {
          progress: 15000,
        });
      }

      //
      var where = `&cliente=true&bloqueada=false&ativa=true`;

      const response = await axios.get(
        `${API.clientes}?email=${email}${where}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );

      //função que troca null por "" para evitar erro em tela para objeto nulo .
      var k = '';
      var v = ';';
      for (const obj of response.data.data) {
        if (typeof obj !== 'object') continue;
        for (k in obj) {
          if (!obj.hasOwnProperty(k)) continue;
          v = obj[k];
          if (v === null || v === undefined) {
            obj[k] = '';
          }
        }
      }
      sessionStorage.setItem('clientes', JSON.stringify(response.data.data));
      toast.done(toastId);
      window.location.reload();
      //  console.log(list_clients);
    } catch (err) {
      toast.done(toastId);
      toast.error('Nenhum cliente encontrado');
    }
  };
  // Submit
  const onSubmit = async data => {
    try {
      buttonRef.current.disabled = true;

      const {
        ativa,
        bloqueada,
        tipo,
        nome,
        apelido,
        cnpj,
        inscricaoEstadual,
        rg,
        cpf,
        enderecoNumero,
        enderecoComplemento,
        enderecoPais,
        enderecoCep,
        observacoes,
        telefone,
        email,
        email_nfe
      } = fields;

      await axios.post(
        `${API.clientes}`,
        {
          ativa: true,
          bloqueada: false,
          tipo,
          nome,
          apelido,
          cnpj,
          inscricaoEstadual,
          cpf,
          rg,
          enderecoLogradouro: logradouroApi,
          enderecoNumero,
          enderecoComplemento,
          enderecoBairro: bairroApi,
          enderecoCidade: cidadeApi,
          enderecoEstado: estadoApi,
          enderecoPais,
          enderecoCep,
          observacoes,
          telefone,
          email,
          email_nfe,
          vendedorPadrao: {
            id: valueAutoId,
            nome: valueAutoNome,
          },
        },
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      setTimeout(() => {
        buttonRef.current.disabled = false;

      }, 1000);

      loadClients();
    } catch (err) {
      setTimeout(() => {
        buttonRef.current.disabled = false;
      }, 5000);

      //  buttonRef.current.disabled = false;
      toast.error('Falha na criação, verifique seus dados');
    }
  };

  // AutoComplete
  const [auto, setAuto] = useState();
  let value = '';
  const [valueAutoId, setValueAutoId] = useState(value);
  const [valueAutoNome, setValueAutoNome] = useState(value);

  useEffect(() => {
    async function handleReq() {
      try {
        const response = await axios.get(`${API.vendedores}?email=${email}`, {
          headers: {
            'x-access-token': token,
          },
        });

        const list = response.data.data.map(item => {
          return { value: item.NUMCAD1, label: item.NOME };
        });

        setAuto(list);
      } catch (err) {
        toast.error('Erro ao listar vendedores');
      }
    }

    handleReq();
  }, []);

  const [logradouroApi, setLogradouro] = useState('');
  const [cidadeApi, setCidade] = useState('');
  const [bairroApi, setBairro] = useState('');
  const [estadoApi, setEstado] = useState('');
  const [complementoApi, setComplemento] = useState('');
  // Cep
  const handleCep = async e => {
    try {
      const response = await axios.get(
        `https://viacep.com.br/ws/${e.target.value}/json`,
      );
      const {
        logradouro: logradouroApi,
        bairro: bairroApi,
        uf: ufApi,
        localidade: localidadeApi,
        complemento: complementoApi,
      } = response.data;

      setLogradouro(logradouroApi);
      setBairro(bairroApi);
      setEstado(ufApi);
      setCidade(localidadeApi);
      setComplemento(complementoApi);
    } catch (err) {
      toast.error('Cep inválido');
    }
  };

  // Selects
  return (
    <div>
      <button className={classes.button} type="button" onClick={handleOpen}>
        <AddCircleOutlineIcon />
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <input type="hidden" ref={register} name="id" />

                <Grid item xs={12} sm={12} lg={12}>
                  <center>
                    <h5>CLIENTE</h5>
                  </center>
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    select
                    label="Tipo"
                    name="tipo"
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required="required"
                    onChange={e => handleRequiretipo(e.target.value)}
                  >
                    <MenuItem value={"JURIDICA"}>JURIDICO</MenuItem>
                    <MenuItem value={'FISICA'}>FÍSICA</MenuItem>

                  </TextField>

                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    id="nome"
                    label="Nome"
                    type="text"
                    style={{ width: '100%' }}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    id="apelido"
                    label="Apelido"
                    type="text"
                    onChange={handleChange}
                    style={{ width: '100%' }}
                    required="required"
                    type="text"
                    ref={register}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                {tipoRequired ? (
                  <>
                    <Grid item xs={12} sm={12} lg={4}>
                      <InputMask
                        mask="99.999.999/9999-99"
                        onChange={handleChange}
                      >
                        <TextField
                          id="cnpj"
                          label="Cnpj"
                          name="cnpj"
                          type="text"
                          style={{ width: '100%' }}
                          required={required ? required : 'required'}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <TextField
                        id="inscricaoEstadual"
                        label="Inscrição Estadual"
                        name="inscricaoEstadual"
                        type="text"
                        onChange={handleChange}
                        style={{ width: '100%' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required={required ? required : 'required'}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={12} lg={4}>
                      <InputMask mask="999.999.999-99" onChange={handleChange}>
                        <TextField
                          label="CPF"
                          name="cpf"
                          id="cpf"
                          type="text"
                          required="required"
                          style={{ width: '100%' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </InputMask>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={4}>
                      <TextField
                        label="RG"
                        name="rg"
                        id="rg"
                        type="text"
                        onChange={handleChange}
                        required="required"
                        style={{ width: '100%' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    id="enderecoCep"
                    label="CEP"
                    name="enderecoCep"
                    type="text"
                    required="required"
                    onChange={handleChange}
                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={handleCep}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    label="Endereço"
                    id="enderecoLogradouro"
                    name="enderecoLogradouro"
                    type="text"

                    required="required"
                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={logradouroApi}
                    onChange={e => setLogradouro(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    label="Número"
                    id="enderecoNumero"
                    name="enderecoNumero"
                    required="required"
                    type="text"
                    onChange={handleChange}
                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    label="Complemento"
                    id="enderecoComplemento"
                    name="enderecoComplemento"
                    type="text"
                    onChange={handleChange}
                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    label="Bairro"
                    id="enderecoBairro"
                    name="enderecoBairro"
                    type="text"

                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    ref={register}
                    required="required"
                    value={bairroApi ? bairroApi : ''}
                    onChange={e => setBairro(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    id="enderecoCidade"
                    label="Cidade"
                    name="enderecoCidade"
                    type="text"

                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required="required"
                    value={cidadeApi ? cidadeApi : ''}
                    onChange={e => setCidade(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    id="enderecoEstado"
                    label="Estado"
                    name="enderecoEstado"
                    type="text"
                    onChange={handleChange}
                    required="required"
                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={estadoApi ? estadoApi : ''}
                    onChange={e => setEstado(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    label="País"
                    id="enderecoPais"
                    name="enderecoPais"
                    required="required"
                    defaultValue="Brasil"
                    onChange={handleChange}

                    type="text"
                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <label>Vendedor</label>
                  <Select
                    options={auto}
                    loadOptions={auto}
                    isClearable={true}
                    onChange={value => {
                      setValueAutoId(value !== null ? value.value : '');
                      setValueAutoNome(value !== null ? value.label : '');
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                  <TextField
                    label="Observações"
                    id="observacoes"
                    name="observacoes"
                    multiline
                    rows={5}
                    type="text"
                    onChange={handleChange}
                    style={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                  <center><h5>Contatos </h5></center>
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    label="Email"
                    id="email"
                    name="email"
                    required="required"
                    type="text"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <InputMask mask="(99)999999999"
                    maskPlaceholder=""
                    onChange={handleChange}>
                    <TextField
                      label="Telefone"
                      id="telefone"
                      name="telefone"
                      type="text"
                      required="required"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </InputMask>

                </Grid>

                <Grid item xs={12} sm={12} lg={4}>
                  <TextField
                    label="Email NFE"
                    id="email_nfe"
                    name="email_nfe"
                    type="text"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>




                <Grid item xs={12} sm={12} lg={8}>

                  <span>* Campos obrigatórios</span>
                </Grid>
              </Grid>
              <ButtonStyled ref={buttonRef} variant="contained" color="primary">
                Criar
              </ButtonStyled>
            </Form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
