import React, { useEffect, useState } from 'react';
// import { useSelector } from "react-redux";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Pesquisa } from './styles';
import { Form, ButtonStyled } from './styles';
import Autocomplete from 'react-autocomplete';
// import InputMask from "react-input-mask";

// import InputMask from "react-input-mask";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { toast } from 'react-toastify';

import ModalClientes from '../../components/Clientes/ModalClientes';
//import ModalClientesContatos from "../../components/Clientes/ModalClientesContatos";
import DataTable from 'components/Table/Table.js';
//import ModalClientesView from "../../components/Clientes/ModalClientes";

import ModalCreate from '../../components/Clientes/ModalCreate';
// import { PanoramaFishEyeOutlined } from "@material-ui/icons";
import { API } from '../../config/api';
import { TablePagination, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: '100vh',
    paddingTop: '2px',
  },
}));

function createData(
  edit,
  id,
  apelido,
  cnpj,
  enderecoLogradouro,
  enderecoBairro,
  enderecoCidade
 
) {
  return {
    edit,
    id,
    apelido,
    cnpj,
    enderecoLogradouro,
    enderecoBairro,
    enderecoCidade
  };
}

const rowHead = [
  { field: 'edit' },
  {
    title: 'Código',
    field: 'id',
    defaultSort: 'desc',
    headerStyle: {
      width: 10,
      maxWidth: 10,
      color: '#fff',
    },
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Apelido',
    field: 'apelido',
    cellStyle: {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'Cnpj',
    field: 'cnpj',
    cellStyle: {
      fontSize: '12px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
  },
  {
    title: 'Endereço',
    field: 'enderecoLogradouro',
    cellStyle: {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'Bairro',
    field: 'enderecoBairro',
    cellStyle: {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'Cidade',
    field: 'enderecoCidade',
    cellStyle: {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  }

];

export default function Clientes() {
  const classes = useStyles();
  var clientes = '';
  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  const perfil = sessionStorage.getItem('perfil');
  // const clientes = JSON.parse(sessionStorage.getItem("clientes"));

  const { register, getValues } = useForm();
  const [listClientes, setListClientes] = useState([]);
  const [load, setLoad] = useState(true);
  const [PanelOpen, setPanelOpen] = React.useState(false);

  // AutoComplete
  const [auto, setAuto] = useState([]);

  useEffect(() => {
    async function handleClean() {
      setValueAutoId('');
    }
    if (valueAutoNome.length === 0) {
      handleClean();
    }
  }, [valueAutoNome]);

  useEffect(() => {
    const loadClients = async () => {
      try {
        setLoad(true);

        var toastId = null;
        if (toastId === null) {
          //toastId = toast.info('Carregando dados do sistema, aguarde.',{ progress: 15000 });
        }

        //
        var where = `&cliente=true&ativa=true`;

        const response = await axios.get(
          `${API.clientes}?email=${email}${where}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );

        //função que troca null por "" para evitar erro em tela para objeto nulo .
        var k = '';
        var v = ';';
        for (const obj of response.data.data) {
          if (typeof obj !== 'object') continue;
          for (k in obj) {
            if (!obj.hasOwnProperty(k)) continue;
            v = obj[k];
            if (v === null || v === undefined) {
              obj[k] = '';
            }
          }
        }

        //console.log(response.data.data);
        setListClientes(response.data.data);
        setLoad(false);
      } catch (err) {
        setLoad(false);
        toast.done(toastId);
        toast.error('Nenhum cliente encontrado');
      }
    };

    const req = async () => {
      try {
        loadClients();
        setLoad(true);
      } catch (err) {
        if (err.response && err.response.status === 402) {
          setLoad(false);

          //token expirado
          toast.error('Sua sessão expirou, favor efetuar login');
          sessionStorage.clear();
        } else {
          toast.error('Erro ao carregar lista');
        }
      }
    };

    req();
  }, []);

  useEffect(() => {
    async function handleReq() {
      try {
        const response = await axios.get(
          `${API.vendedores}?email=${email}`,

          {
            headers: {
              'x-access-token': token,
            },
          },
        );

        const list = response.data.data;
        setAuto(list);
        setLoad(false);
      } catch (error) {
        setLoad(false);
      }
    }

    handleReq();
  }, []);

  let value = '';
  let valueId = '';
  if (perfil === 'vendedor') {
    value = !clientes ? '' : clientes[0].vendedorPadrao.nome;
    valueId = !clientes ? '' : clientes[0].vendedorPadrao.id;
  }
  const [valueAutoId, setValueAutoId] = useState(valueId);
  const [valueAutoNome, setValueAutoNome] = useState(value);

  const Pesquisaitem = (data, e) => {
    e.preventDefault();
    setLoad(true);
    handleSearch(data);
    setPanelOpen(false);
  };

  const handleSearch = async data => {
    try {
      setListClientes('');

      var axios_search = '';

      if (data.codigo > 0) {
        axios_search = `${API.clientes}/${data.codigo}`;
      } else {
        if (
          data.vendedorPadraoId > 0 ||
          data.nome !== '' ||
          data.cnpj != '' ||
          data.cidade !== ''
        ) {
          var where = '';
          if (perfil !== 'vendedor') {
            where = data.vendedorPadraoId
              ? where + `&vendedorPadrao.id=${data.vendedorPadraoId}`
              : where;
          }
          var nome = encodeURIComponent(data.nome);

          where = data.nome
            ? where + `&apelido='*${nome}*'&cliente=true&ativa=true`
            : where;
          where = data.cnpj ? where + `&cnpj=*${data.cnpj}*` : where;
          where = data.cidade
            ? where + `&enderecoCidade='${data.cidade}%'`
            : where;
          axios_search = `${API.clientes}?email=${email}${where}`;
        } else {
          var where = `&cliente=true&ativa=true`;
          axios_search = `${API.clientes}?email=${email}${where}`;
        }
      }

      const response = await axios.get(`${axios_search}`, {
        headers: {
          'x-access-token': token,
        },
      });
      const item = response.data.data;
      // console.log(item);
      //função que troca null por "" para evitar erro em tela para objeto nulo .
      var k = '';
      var v = ';';
      for (const obj of item) {
        if (typeof obj !== 'object') continue;
        for (k in obj) {
          if (!obj.hasOwnProperty(k)) continue;
          v = obj[k];
          if (v === null || v === undefined) {
            obj[k] = '';
          }
        }
      }
      //   console.log(item);
      setListClientes(item);
      setLoad(false);
    } catch (err) {
      setLoad(false);

      if (err.response && err.response.status === 402) {
        //token expirado
        toast.error('Token expirado.Efetue o login novamente');
        sessionStorage.clear();
      } else if (err.response && err.response.status === 404) {
        //Nenhum cliente encontrado
        toast.error('Nenhum cliente encontrado com esses parâmentros.');
        setListClientes([]);
      } else {
        toast.error('Erro ao buscar.Verifique os parâmetros');
      }
    }
  };

  const rowsList = listClientes
    ? listClientes.map(item => {
        const {
          edit,
          id,
          apelido,
          cnpj,
          enderecoLogradouro,
          enderecoBairro,
          enderecoCidade
        } = item;

        const row = createData(
          <ModalClientes data={item} />,
          id,
          apelido,
          cnpj,
          enderecoLogradouro,
          enderecoBairro,
          enderecoCidade
        );

        return row;
      })
    : [{ error: 'Não encontrado' }];

  return (
    <>
      <Paper className={classes.paper}>
        <Pesquisa>
          <div>
            <ExpansionPanel expanded={PanelOpen}>
              <ExpansionPanelSummary
                expanded={PanelOpen}
                onClick={() => {
                  setPanelOpen(!PanelOpen);
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography component={'span'} className={classes.heading}>
                  Painel de pesquisa
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails expanded={true}>
                <Typography component={'div'}>
                  <Form>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} lg={2}>
                        <div className="input">
                          <label>ID</label>
                          <input type="number" name="codigo" ref={register} />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={6}>
                        <div className="input">
                          <label>Nome</label>
                          <input type="text" name="nome" ref={register} />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={4}>
                        <div className="input">
                          <label>Cnpj</label>
                          <input type="text" name="cnpj" ref={register} />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <div className="input">
                          <label>Cidade</label>
                          <input type="text" name="cidade" ref={register} />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={8}>
                        <div className="input">
                          <label>Vendedor</label>
                          <input
                            name="vendedorPadraoId"
                            type="hidden"
                            ref={register}
                            defaultValue={valueAutoId}
                          />
                          <input
                            name="vendedorPadraoNome"
                            type="hidden"
                            ref={register}
                          />
                          <Autocomplete
                            items={auto}
                            wrapperStyle={{
                              position: 'relative',
                              zIndex: '15',
                              display: 'inline-block',
                            }}
                            shouldItemRender={(item, value) =>
                              item.NOME.toLowerCase().indexOf(
                                value.toLowerCase(),
                              ) > -1
                            }
                            getItemValue={item => {
                              setValueAutoId(item.NUMCAD1);
                              return item.NOME;
                            }}
                            renderItem={(item, isHighlighted) => (
                              <div
                                key={item.NUMCAD1}
                                inputVariant="outlined"
                                style={{
                                  background: isHighlighted
                                    ? 'lightgray'
                                    : 'white',
                                  width: '100%',
                                }}
                              >
                                <span
                                  key={item.NUMCAD1}
                                  style={{
                                    fontWeight: isHighlighted ? 700 : 400,
                                  }}
                                >
                                  {item.NOME}
                                </span>
                              </div>
                            )}
                            value={valueAutoNome}
                            onChange={e => setValueAutoNome(e.target.value)}
                            onSelect={val => setValueAutoNome(val)}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <ButtonStyled
                      variant="contained"
                      color="primary"
                      onClick={e => Pesquisaitem(getValues(), e)}
                    >
                      Pesquisar
                    </ButtonStyled>
                  </Form>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </Pesquisa>
        <ModalCreate />

        <DataTable
          load={load}
          rows={rowsList}
          rowHead={rowHead}
          sort={true}
          title={'Clientes'}
        />
      </Paper>
    </>
  );
}
