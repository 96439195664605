/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import DataTable from 'components/Table/Table.js';
import ItemProdutos from 'components/Pedidos/ModalItemProdutos';
import { withStyles } from '@material-ui/core/styles';

import IconRequestPass from '@material-ui/icons/CheckCircle';
import IconRequestfailure from '@material-ui/icons/Cancel';

import { ToastContainer, toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Card, CardHeader, CardContent } from '@material-ui/core';

import { ReactMultiEmail } from 'react-multi-email';

import Badge from '@material-ui/core/Badge';

import PropTypes from 'prop-types';

import Autocomplete from 'react-autocomplete';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import ParcelNumber from 'components/Pedidos/RequestParcelNumber';
import { API } from '../../config/api';
import history from '../../services/history';
import Async from 'react-select/async';
import debounce from 'debounce-promise';
import Select from 'react-select';
import InfoClient from 'components/Clientes/InfoClientes/index';

import {
  Pesquisa,
  Form,
  ButtonStyled,
  ButtonRequest,
  Input,
  FormAuto,
  PaperStyle,
} from './stylesFinaliza';

import axios from 'axios';
import moment from 'moment';

// Função para criar OBJ dos Pedidos
function createRequest(
  view,
  idReq,
  busines,
  dateProg,
  total,

  idOperator,
  idCarriers,
) {
  return {
    view,
    idReq,
    busines,
    dateProg,
    total,
    idOperator,
    idCarriers,
  };
}

const headRequest = [
  {
    title: '',
    field: 'view',
    headerStyle: {
      width: 10,
      maxWidth: 10,
      padding: 1,
    },
    cellStyle: {
      fontSize: '10px',
      whiteSpace: 'nowrap',
      padding: '1px',
    },
  },
  {
    title: 'Referência',
    field: 'idReq',
  },
  {
    title: 'Empresa',
    field: 'busines',
  },
  {
    title: 'Programação',
    field: 'dateProg',
  },

  {
    title: 'Total',
    field: 'total',
  },
];

// Funções para criar o OBJ FinishRequest
function createDataFinishRequest(
  mailsent,
  icon,
  items,
  numeroSistema,
  numeroReferencia,
  prz_pag,
  programacao,
  cliente,
  status,
) {
  return {
    mailsent,
    icon,
    items,
    numeroSistema,
    numeroReferencia,
    prz_pag,
    programacao,
    cliente,
    status,
  };
}

const rowHeadRequest = [
  {
    title: '',
    field: 'mailsent',
    cellStyle: {
      fontSize: '12px',
    },
  },

  {
    title: 'Status',
    field: 'icon',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: '',
    field: 'items',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'N.Pedido',
    field: 'numeroSistema',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Referência',
    field: 'numeroReferencia',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Prazo Pagamento',
    field: 'prz_pag',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Programação',
    field: 'programacao',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Cliente',
    field: 'cliente',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Status',
    field: 'status',
    cellStyle: {
      fontSize: '12px',
    },
  },
];

function FinalizaPedido({ itemCart, Transp, Clients }) {
  const [isConfirmReq, setisConfirmReq] = useState(true);
  const [isFinishRequest, setIsFinishRequest] = useState(false);
  const [isViewRequest, setIsViewRequest] = useState(false);

  const [lista, setLista] = useState([]);
  const [listCart, setListCart] = useState([itemCart]);
  const [loading, setLloading] = useState(true);
  const [valueIp, setValue] = useState('');

  const [progress, SetProgress] = useState(false);
  const [itemSendRequest, setItemSendRequest] = useState([]);
  const [prz_pagCliente, setPrzPagCliente] = useState('');

  const [autoCliente, setAutoCliente] = useState([]);
  const [autoVendedor, setAutoVendedor] = useState([]);
  const [autoTransp, setTransp] = useState(Transp);

  const [listRequest, setListRequest] = useState([]);

  const [nomeCliente, setNomeCliente] = useState('');
  const [nomeClienteForm, setNomeClienteForm] = useState('');

  const [nomeTransp, setNomeTransp] = useState('');
  const [nomeTranspForm, setNomeTranspForm] = useState('');

  const [nomeTranspRedespacho, setNomeTranspRedespacho] = useState('');
  const [nomeTranspRedespachoForm, setNomeTranspRedespachoForm] = useState('');

  const [idCliente, setIdCliente] = useState('');
  const [idClienteForm, setIdClienteForm] = useState('');

  const [vendedorP, setVendedorP] = useState('');
  const [idVendedorP, setIdVendedorP] = useState('');
  const [idTransp, setIdTransp] = useState('');
  const [idTranspRedespacho, setIdTranspRedespacho] = useState('');
  const [obs, setObs] = useState('');
  const [prz_pag, setPrzPag] = useState('');
  const [vlparcelas, setVlParcelas] = useState('0');
  const [qtdparcelas, setQtdParcelas] = useState('1');
  const [prz_medio, setPrzMedio] = useState('0');
  const [vl_tot_pedido, setVl_Tot_Pedido] = useState(0);
  const [dataParent, setDataParent] = useState('');

  const [emails, setEmail] = useState([]);
  const [emailsAutomatico, setEmailAutomatico] = useState([]);
  const [frete, setFrete] = useState('');

  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  const userId = sessionStorage.getItem('id');
  const userIdErp = sessionStorage.getItem('id_erp');
  const clientes = sessionStorage.getItem('clientes');

  const isRequest = listCart.length === 0;

  const [vendedorNomeForm, setVendedorNomeForm] = useState('');
  const [idVendedorForm, setIdVendedorForm] = useState('');

  const [paymentForm, setPaymentForm] = useState('');
  const [forma_pagamento_default, setforma_pagamento_default] = useState([
    { label: 'ANTECIPADO', value: 'ANTECIPADO' },
    { label: 'BOLETO', value: 'BOLETO' },
    { label: 'BOLETO BX', value: 'BOLETO BX' },
    { label: 'BONIFICAÇÃO', value: 'BONIFICAÇÃO' },
    { label: 'BX', value: 'BX' },
    { label: 'CARTÃO DE CRÉDITO', value: 'CARTÃO DE CRÉDITO' },
  ]);

  const [prioridades, setPrioridade] = useState([
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' },
    { label: 'E', value: 'E' },
  ]);
  const [qtd_parcelas_default, setQtd_parcelas_default] = useState([
    { label: '1x', value: '1' },
    { label: '2x', value: '2' },
    { label: '3x', value: '3' },
    { label: '4x', value: '4' },
    { label: '5x', value: '5' },
  ]);
  const [parcelasCartao, setParcelasCartao] = useState('');
  const [prioridadeEscolhida, setPrioridadeEscolhida] = useState('A');

  const refInput = React.createRef(null);

  const useStyles = makeStyles(theme => ({
    paper: {
      backgroundColor: theme.palette.background.paper,
      paddingTop: '5px',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    paper2: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: '50%',
      minHeight: '50%',
    },
    progress: {
      marginTop: 200,

      textAlign: 'center',
      alignItems: 'center',
      width: '100%',
      '& > * + *': {},
    },
    root: {
      display: 'flex',
      minWidth: 320,
      maxWidth: 500,
      flexDirection: 'column', //change to row for horizontal layout
      '& .MuiCardHeader-root': {
        backgroundColor: 'yellow',
      },
      '& .MuiCardHeader-root': {
        backgroundColor: 'yellow',
      },
      '& .MuiCardHeader-title': {
        //could also be placed inside header class
        backgroundColor: '#FCFCFC',
      },
      '& .MuiCardHeader-subheader	': {
        backgroundImage: 'linear-gradient(to bottom right, #090977, #00d4ff);',
      },
      '& .MuiCardContent-root': {
        backgroundImage: 'linear-gradient(to bottom right, #00d4ff, #00ff1d);',
      },
    },
    header: {
      fontSize: '1.15rem',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 500,
      textAlign: 'center',
      color: 'black',
      lineHeight: 1.4,
    },
    content: {
      display: 'flex',
      minHeight: '100%',
      flexWrap: 'wrap',
    },
    contentItem: {
      flex: 'calc(50% - 4px)',
      '@media (max-width: 500px)': {},
    },
    textContent: {
      fontSize: 18,
      textAlign: 'center',
      border: '1px solid black',
    },
    footer: {
      fontSize: 14,
      backgroundImage: 'linear-gradient(to bottom right, #8c9d9b, #bdcdbf);',
    },
    card: {
      maxWidth: '100%',
      minHeight: '100%',
      maxHeight: '100%',
      boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.3)',
      backgroundColor: '#fafafa',
    },
  }));

  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.text.secondary,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 10,
    },
  }))(TableCell);
  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const StyledTableCellPrimary = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 16,
    },
  }))(TableCell);

  const StyledBadge = withStyles(theme => ({
    badge: {
      right: 4,
      top: 5,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))(Badge);

  const classes = useStyles();

  useEffect(() => {
    //   if(vl_tot_pedido_programacao> 500){
    const loadCreditClient = async () => {
      try {
        const response = await axios.get(
          `${API.infocomerciais}?conta_cliente=${idCliente}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );

        const dadoscomerciais = response.data.data;
        let credito = dadoscomerciais[0].IC_RESERVA_DISPONIVEL;
        let credito_pe = dadoscomerciais[0].IC_CREDITO_DISPONIVEL;
        var status = '';
        //        console.log("credito" + credito);

        // setStatusClient(status);
        setDataParent(credito);
      } catch (err) {}
    };
    loadCreditClient();

    // }
  }, [idCliente]);

  useEffect(() => {
    const loadSales = async () => {
      try {
        const response = await axios.get(`${API.vendedores}?email=${email}`, {
          headers: {
            'x-access-token': token,
          },
        });

        setAutoVendedor(response.data.data);
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error('Sua sessão expirou, favor efetuar o login');
        } else {
          toast.error('Erro ao carregar lista de vendedores');
        }
      }
    };

    // loadCarriers();
    setAutoCliente(JSON.parse(clientes));

    loadSales();
    confirmRequest();
    //   console.log(">>>> TOKEN:", token);
    //  console.log(">>>> userIdErp:", userIdErp);
  }, []);

  useEffect(() => {
    const getPrazoPagamento = async () => {
      try {
        const response = await axios.get(
          `${API.prazomedio}?consulta=${prz_pag}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        if (
          (paymentForm == 'BOLETO' || paymentForm == 'BOLETO BX') &&
          response.data.data[0].QTD_PARCELAS > 1 &&
          vl_tot_pedido / response.data.data[0].QTD_PARCELAS < 250
        ) {
          toast.error('Parcela mínima para boletos: R$ 250,00');
          setVlParcelas('');
          setPrzMedio('');
          setPrzPag('');
        } else {
          setVlParcelas(
            response.data.data[0].QTD_PARCELAS > 0
              ? (
                  vl_tot_pedido / response.data.data[0].QTD_PARCELAS
                ).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
              : 0,
          );
          setQtdParcelas(response.data.data[0].QTD_PARCELAS);
          setPrzMedio(response.data.data[0].PRAZO_MEDIO);
        }
        if (response.data.data[0].PRAZO_MEDIO > 120) {
          toast.error('Prazo médio não pode exceder 120 dias.');
          setVlParcelas('');
          setPrzMedio('');
          setPrzPag('');
        }
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error('Sua sessão expirou, favor efetuar o login');
        } else {
          toast.error('Erro ao obter prazo médio.');
        }
      }
    };
    setVlParcelas('');
    if (prz_pag != '') {
      getPrazoPagamento();
    }
  }, [prz_pag]);

  useEffect(() => {
    if (paymentForm == 'CARTÃO DE CRÉDITO' && parcelasCartao > 0) {
      //console.log(vl_tot_pedido / parcelasCartao);

      setVlParcelas(
        (vl_tot_pedido / parcelasCartao).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
      );
      setQtdParcelas(parcelasCartao);
      setPrzMedio(0);
    }
  }, [parcelasCartao]);

  const loadCarriers = async (inputValue, callback) => {
    try {
      var where = `&concat_transp='${inputValue}'`;
      const responseTransp = await axios.get(
        `${API.transportadoras}?email=${email}${where}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );

      const data = responseTransp.data.data.map(item => {
        return {
          value: item.NUMCAD1,
          label: (
            <span
              dangerouslySetInnerHTML={{
                __html:
                  '<strong>Id:</strong> ' +
                  item.NUMCAD1 +
                  '<br> <strong>Nome/Apelido:</strong>' +
                  item.NOME +
                  '/' +
                  item.APELIDO +
                  '<br><strong>CNPJ:</strong>' +
                  item.CGC +
                  '<hr>',
              }}
            />
          ),
          labelshow: item.NUMCAD1 + ' ' + item.NOME + ' ' + item.CGC,
          dados: item,
        };
      });
      //console.log(data);
      return data;
    } catch (err) {
      if (err.response && err.response.status === 402) {
        //token expirado
        toast.error('Sua sessão expirou, favor efetuar o login');
        sessionStorage.clear();
      } else {
        toast.error('Erro ao carregar lista de transportadoras');
      }
    }
  };

  //DEBOUNCE TRANSPORTADORA
  const loadOptionsTransp = (inputValue, callback) =>
    loadCarriers(inputValue, callback);

  const debouncedLoadOptionsTransp = debounce(loadOptionsTransp, 3000, {
    leading: false,
  });

  const loadClients = async (inputValue, callback) => {
    try {
      var busca = encodeURIComponent(inputValue);

      var where = `&concat_cliente='*${busca}*'&bloqueada=false&ativa=true`;

      const response = await axios.get(
        `${API.clientes}?email=${email}${where}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );

      //função que troca null por "" para evitar erro em tela para objeto nulo .
      var k = '';
      var v = ';';
      for (const obj of response.data.data) {
        if (typeof obj !== 'object') continue;
        for (k in obj) {
          if (!obj.hasOwnProperty(k)) continue;
          v = obj[k];
          if (v === null || v === undefined) {
            obj[k] = '';
          }
        }
      }

      const data = response.data.data.map(item => {
        return {
          value: item.id,
          label: (
            <span
              dangerouslySetInnerHTML={{
                __html:
                  '<strong>Id:</strong> ' +
                  item.id +
                  '<br> <strong>Nome/Apelido:</strong>' +
                  item.nome_concat +
                  '<br><strong>CNPJ/CPF:</strong>' +
                  item.cnpj +
                  item.cpf +
                  '<br><strong>Cidade:</strong>' +
                  item.enderecoCidade +
                  '/' +
                  item.enderecoEstado +
                  '<hr>',
              }}
            />
          ),
          labelshow: item.id + ' ' + item.nome_concat + ' ' + item.cnpj,
          dados: item,
        };
      });
     // console.log(data);
      return data;
    } catch (err) {}
  };

  function getPaymentData(valor) {
    setPrzPag(valor);
  }
  //DEBOUNCE CLIENTES
  const loadOptionsClientes = (inputValue, callback) =>
    loadClients(inputValue, callback);

  const debouncedLoadOptionsClientes = debounce(loadOptionsClientes, 3000, {
    leading: false,
  });

  async function getTranspData(val) {
    if (idCliente > 0) {
      try {
        setObs('');

        const response = await axios.get(
          `${API.clientes}/transportadorasByClienteId/${idClienteForm}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        if (response.data.data[0].U4_PRAZO_PAGAMENTO !== null) {
          setPrzPagCliente(response.data.data[0].U4_PRAZO_PAGAMENTO);
        }
        //console.log(response.data.data[0].OBSERVACOES);

        if (response.data.data[0].TRANSPORTADORA_ID !== null) {
          // console.log(response.data.data[0].TRANSPORTADORA_ID);
          setIdTransp(response.data.data[0].TRANSPORTADORA_ID);
          setNomeTranspForm(response.data.data[0].TRANSPORTADORA_APELIDO);
          setNomeTransp(
            response.data.data[0].TRANSPORTADORA_APELIDO
              ? (
                  response.data.data[0].TRANSPORTADORA_APELIDO +
                  '(' +
                  response.data.data[0].TRANSPORTADORA_CNPJ +
                  ')'
                ).toUpperCase()
              : '',
          );
          setIdTranspRedespacho(
            response.data.data[0].REDESPACHO_ID
              ? response.data.data[0].REDESPACHO_ID
              : '',
          );
          setNomeTranspRedespacho(
            response.data.data[0].REDESPACHO_APELIDO
              ? response.data.data[0].REDESPACHO_APELIDO
              : '',
          );
          setNomeTranspRedespachoForm(
            response.data.data[0].REDESPACHO_APELIDO
              ? response.data.data[0].REDESPACHO_APELIDO +
                '(' +
                response.data.data[0].REDESPACHO_CNPJ
                ? response.data.data[0].REDESPACHO_CNPJ
                : '' + ')'
              : '',
          );
        }
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error('Sua sessão expirou, favor efetuar o login');
          //   sessionStorage.clear();
        } else {
          //      sessionStorage.clear();
          //   toast.error("Erro ao carregar lista de vendedores");
        }
      }
    }
  }

  async function confirmRequest() {
    setisConfirmReq(true);

    var prazo_medio = '';

    const response = await axios.get(`${API.carrinho}?email=${email}`, {
      headers: {
        'x-access-token': token,
      },
    });

    const itemRequestSend = [];
    const idNumberRequest = [];
    var total_pedido_geral = 0;
    for (let index = 0; index < response.data.data.length; index++) {
      const itemRequest = {
        email,
        numero_pedido: 0,
        cliente_id: idCliente,
        clienteNome: nomeCliente,
        vendedor_id: idVendedorP,
        vendedor_nome: vendedorNomeForm,
        operador_id: userIdErp,
        prazo_pagamento: '',
        prazo_pagamento_media: '',
        valor_parcelas: '',
        referencia: '',
        forma_pagamento: '',
        emissao: moment().format('YYYY/MM/DD'),
        empresa_id: 0,
        lista_preco: '',
        tipo_frete: frete,
        observacoes: obs,
        emails_adicionais: email,
        dt_previsao_entrega: '',
        transportadora: 0,
        transportadora_nome: '',
        redespacho: 0,
        redespacho_nome: '',
        status: '$digitando',
        items: [...response.data.data[index]],
      };

      const respedidos = await axios.get(`${API.numeropedido_erp}`, {
        headers: {
          'x-access-token': token,
        },
      });

      const listProducts = [];
      let companyName = '';
      let companyId = 0;
      let dateProgram = '';
      let totalPedido = 0;
      let data_prog = '';

      // Coleta os itens do pedido (Produtos)
      for (let idx = 0; idx < response.data.data[index].length; idx++) {
        const i = response.data.data[index][idx];
        //  console.log(i);
        const item = {
          ID: i.ID,
          ITEM_ID: i.ITEM_ID,
          ITEM_NOME: i.ITEM_NOME,
          EMPRESA_ID: i.EMPRESA_ID,
          EMPRESA_APELIDO: i.EMPRESA_APELIDO,
          DATA_PROGRAMACAO: i.DATA_PROGRAMACAO,
          QUANTIDADE: i.QUANTIDADE,
          ITEM_UNIDADE: i.ITEM_UNIDADE,
          ITEM_QUALIDADE: i.ITEM_QUALIDADE,
          ITEM_GRADE: i.ITEM_GRADE,
          LISTA_PRECO: i.LISTA_PRECO_ID,
          VALOR_UNITARIO: i.VALOR_UNITARIO,
          VALOR_UNITARIO_PADRAO: i.VALOR_UNITARIO_PADRAO,
          VALOR_TOTAL: i.VALOR_TOTAL,
          TIPO_VENDA: i.TIPO_VENDA,
          PROGRAMACAO_NUMERO: i.PROGRAMACAO_NUMERO,
          PROGRAMACAO_ITEM_ID: i.PROGRAMACAO_ITEM_ID,
          CARRINHO_USUARIO_ID: i.CARRINHO_USUARIO_ID,
          CARRINHO_CONTA_ID: i.CARRINHO_CONTA_ID,
        };
        listProducts.push(item);
        totalPedido = totalPedido + i.QUANTIDADE * i.VALOR_UNITARIO;
        total_pedido_geral =
          total_pedido_geral + i.QUANTIDADE * i.VALOR_UNITARIO;
        if (companyName === '') {
          companyName = i.EMPRESA_APELIDO;
          dateProgram = i.TIPO_VENDA.includes('P.E.')
            ? i.TIPO_VENDA
            : moment(i.DATA_PROGRAMACAO).format('DD/MM/YYYY');
          companyId = i.EMPRESA_ID;
          data_prog = i.DATA_PROGRAMACAO;
        }
      }

      itemRequest.numero_pedido = respedidos.data.data.numero;
      itemRequest.empresa_id = companyId;
      itemRequest.items = listProducts;
      itemRequest.dt_previsao_entrega = data_prog;
      const row = createRequest(
        <ItemProdutos itemCart={listProducts} />,

        <Input
          type="text"
          name="referenc"
          className="index"
          onChange={e => (itemRequest.referencia = e.target.value)}
          style={{
            minWidth: '7rem',
            maxWidth: '7rem',
            padding: '1.125rem 0.15rem',
            height: 'calc(1.5em + 0.35rem + 2px)',
            fontSize: '12px',
          }}
        />,
        companyName,
        dateProgram,

        <Input
          type="text"
          name="totalRequest"
          disabled
          value={totalPedido.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
          style={{
            minWidth: '10rem',
            textAlign: 'right',
            padding: '1.125rem 0.15rem',
          }}
        />,
      );
      idNumberRequest.push(row);
      itemRequestSend.push(itemRequest);
    }
    setVl_Tot_Pedido(total_pedido_geral);
    // //console.log('total do pedido' + total_pedido_geral);
    setItemSendRequest(itemRequestSend);
    setListCart(idNumberRequest);
    setLloading(false);
  }

  async function orderViewCancel() {
    setIsViewRequest(false);
    setisConfirmReq(true);
  }
  async function orderView() {
    var prazo_pag =
      paymentForm == 'CARTÃO DE CRÉDITO' ? parcelasCartao + 'x' : prz_pag;
    var obs_concat = 'Forma de pagamento: ' + paymentForm + '(' + prazo_pag +')'+
    '\n' + 'Prioridade: ' + prioridadeEscolhida + '\n' + `${obs}`;
    setObs(obs_concat);

    //setIsViewRequest();
    //if (isConfirmReq && !isFinishRequest && !isViewRequest && !progress) {
    //setIsViewRequest(true);
    for (let index = 0; index < itemSendRequest.length; index++) {
      const i = itemSendRequest[index];
      i.cliente_id = idCliente;
      i.vendedor_id = idVendedorP;
      i.tipo_frete = frete;
      i.observacoes = obs_concat;
      i.transportadora = idTransp;
      i.transportadora_nome = nomeTransp;
      i.redespacho_nome = nomeTranspRedespacho;
      i.redespacho = idTranspRedespacho;
      i.prazo_pagamento =
        paymentForm == 'CARTÃO DE CRÉDITO' ? parcelasCartao : prz_pag;
    }

    if (prz_pag !== '' || parcelasCartao != '') {
      setIsViewRequest(true);
    } else {
      toast.error('Prazo de pagamento não pode ser nulo');
      //  setIsViewRequest(false);
      // setisConfirmReq(true);
    }
  }

  async function orderConfirmation() {
    if (idCliente.length === 0 || idVendedorP === 0) {
      toast.success('Favor preencher os campos obrigatórios');
    } else {
      setIsFinishRequest(true);
      setLloading(true);
      var responseContatos = null;
      let emails = email ? email + ',' : '';
      let emails_disparo_automatico = email ? email + ',' : '';
      try {
        var responseContatos = null;

        //obtem endereço de email do vendedor associado
        const EmailVendedorAssociado = await axios.get(`${API.usuarios}`, {
          headers: {
            'x-access-token': token,
          },
        });

        const emailsvendedores = EmailVendedorAssociado.data.data;
        const returnVendedores = emailsvendedores
          ? emailsvendedores.filter(function (obj) {
              return obj.USUARIO_CONTA_ID_ERP === String(idVendedorP);
            })
          : '';

        //console.log(returnVendedores);

        if (returnVendedores[0]) {
          emails = emails + returnVendedores[0].USUARIO_EMAIL + ',';
          emails_disparo_automatico =
            emails_disparo_automatico + returnVendedores[0].USUARIO_EMAIL + ',';
        }

        responseContatos = await axios.get(
          `${API.clientes}/${idCliente}/contatos`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );

        if (responseContatos) {
          var responseContatosNew = responseContatos.data.data.sort(
            (a, b) => a.prioridade - b.prioridade,
          );
          var count_email = 0;

          responseContatosNew.map(i => {
            if (
              (i.tipo === 'EMAIL' || i.tipo === 'EMAIL_NFE') &&
              count_email == 0
            ) {
              if (i.tipo === 'EMAIL') {
                emails_disparo_automatico =
                  emails_disparo_automatico + `${i.descricao},`;
              }

              emails = emails + `${i.descricao},`;
              count_email++;
            }
          });
          emails = emails.slice(0, -1);
          emails_disparo_automatico = emails_disparo_automatico.slice(0, -1);

          setEmail(emails.split(','));
          setEmailAutomatico(emails_disparo_automatico.split(','));
        }
      } catch (error) {
        //
      }

      const listRequestResponse = [];

      for (let index = 0; index < itemSendRequest.length; index++) {
        const i = itemSendRequest[index];
        i.cliente_id = idCliente;
        i.vendedor_id = idVendedorP;
        i.observacoes = obs;
        i.transportadora = idTransp;
        i.redespacho = idTranspRedespacho;

        var erro = 'nao';
        var response = null;

        try {
          const response2 = await axios.post(`${API.pedidos}`, i, {
            headers: {
              'x-access-token': token,
            },
          });
          if (
            response2.data &&
            response2.data.success &&
            parseInt(response2.data.status) === 200 &&
            response2.data.data.numeroSistema &&
            response2.data.data.conta
              ? response2.data.data.conta.nome
              : ''
          ) {
            //limpa carrinho(somente itens inseridos)
            for (let x = 0; x < i.items.length; x++) {
              try {
                await axios.delete(
                  `${API.carrinhoremoveitem}/${i.items[x].ID}`,
                  {
                    headers: {
                      'x-access-token': token,
                    },
                  },
                );
                //   toast.success("Item removido do carrinho com sucesso");

                //window.location.reload();
              } catch (err) {}
            }

            //atualizar carrinho que pedido foi gerado
            listRequestResponse.push(
              createDataFinishRequest(
                '',
                <IconRequestPass style={{ color: 'green' }} />,
                <ItemProdutos itemCart={i.items} />,
                response2.data.data.numeroSistema,
                i.referencia,
                i.prazo_pagamento,
                moment(i.dt_previsao_entrega).format('DD/MM/YYYY'),
                response2.data.data.conta.nome,
                'Enviado',
              ),
            );
          } else {
            toast.error(
              'Erro ao inserir. Os itens serão mantidos em seu carrinho',
            );

            listRequestResponse.push(
              createDataFinishRequest(
                '',
                <IconRequestfailure style={{ color: 'red' }} />,
                <ItemProdutos itemCart={i.items} />,
                '',
                i.referencia,
                i.prazo_pagamento,
                moment(i.dt_previsao_entrega).format('DD/MM/YYYY'),
                i.clienteNome,
                response2 && response2.data.data && response2.data.data.mensagem
                  ? response2.data.data.mensagem
                  : '',
              ),
            );
          }
        } catch (error) {}
      }
      /*
      ////console.log(listRequestResponse);
      //Enviando pedido automaticamente
      const emailSentTo = emails_disparo_automatico.join();

      var array_pedidos_auto = [];

      for (let index = 0; index < listRequestResponse.length; index++) {
        const i = listRequestResponse[index];
        if (i.numeroSistema > 0) {
          array_pedidos_auto.push({ numero: i.numeroSistema });
        }
      }

      const data_sendEmail = {
        usuario: email,
        emails: emailSentTo,
        titulo: "",
        descricao: "Pedido emitido pelo sistema SalesBreath",
        pedidos: array_pedidos_auto,
      };
      //console.log("dados a enviar");
      //console.log(data_sendEmail);
      try {
        toast.success("Enviando email,aguarde.", { autoClose: 10000 });

        setLloading(true);

        const response_mail = await axios.post(
          `${API.sendmailPedidos}`,
          data_sendEmail,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setLloading(false);

        for (
          let idxmail = 0;
          idxmail < response_mail.data.data.data.length;
          idxmail++
        ) {
          listRequest[idxmail].mailsent = "Email Enviado";
        }
        //  setListRequest(listRequest);
      } catch (error) {
        setLloading(false);
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : "Erro ao enviar email"
        );
      }
      */

      setListRequest(listRequestResponse);
      setLloading(false);
    }
  }

  async function cancelRequest() {
    setListCart([]);
    setLista([]);
    setisConfirmReq(false);
    history.push('/produtos');
  }

  async function sendUserNotification(e) {
    e.preventDefault();
    const emailSentTo = emails.join();
    var answer = window.confirm('Tem certeza que deseja enviar o email ?');
    if (answer) {
      const array_pedidos = [];

      for (let index = 0; index < listRequest.length; index++) {
        const i = listRequest[index];
        if (i.numeroSistema > 0) {
          array_pedidos.push({ numero: i.numeroSistema });
        }
      }

      const data_sendEmail = {
        usuario: email,
        emails: emailSentTo,
        titulo: '',
        descricao: 'Pedido emitido pelo sistema SalesBreath',
        pedidos: array_pedidos,
      };
      //console.log("dados a enviar");
      //console.log(data_sendEmail);
      try {
        toast.success('Enviando email,aguarde.', { autoClose: 10000 });

        setLloading(true);

        const response_mail = await axios.post(
          `${API.sendmailPedidos}`,
          data_sendEmail,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        setLloading(false);

        for (
          let idxmail = 0;
          idxmail < response_mail.data.data.data.length;
          idxmail++
        ) {
          listRequest[idxmail].mailsent = 'Email Enviado';
        }
        setListRequest(listRequest);
      } catch (error) {
        setLloading(false);
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : 'Erro ao enviar email',
        );
      }
    } else {
      return null;
    }
  }

  // Monta a tela conforme condiçoes das variaveis:
  //
  // isConfirmReq:
  // se for FALSE, renderiza tela de produtos para
  // inserção no carrinho, caso seja TRUE, carrinho
  // com produto para realizar processo de finalização de pedido.
  //
  // isFinishRequest:
  // se for FALSE, processo de finalização do pedido em curso.
  // se for TRUE, iniciou o processo de finalização, enviou os pedidos
  // para o back end, e esta exibindo o resulta em tela para o usuário.
  if (!isConfirmReq) {
    return null;
  } else {
    if (progress) {
      return (
        <>
          <div className={classes.progress}>
            <span alignItems="center">
              Estamos carregando a lista de clientes, favor aguardar.
            </span>

            <LinearProgress />
          </div>
        </>
      );
    }

    if (isConfirmReq && !isFinishRequest && !isViewRequest && !progress) {
      // Inicia o processo de confirmação de pedido

      return (
        <>
          {' '}
          <FormAuto>
            <div className="lineForm">
              <Pesquisa>
                <div>
                  <Typography component={'div'}>
                    <FormAuto>
                      <Grid
                        container
                        spacing={3}
                        style={{
                          width: '100%',
                          minHeight: '100%',
                        }}
                      >
                        <Grid item xs={12} lg={5} md={5}>
                          <Card className={classes.card}>
                            <CardHeader
                              className={classes.header}
                              component={Typography}
                              title={'DEFINIÇÕES DA VENDA'}
                            />

                            <CardContent>
                              <Grid
                                container
                                spacing={2}
                                style={{ width: '100%', background: 'white' }}
                              >
                                <Grid item xs={12} lg={12} md={12}>
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{ width: '100%' }}
                                  >
                                    <Grid
                                      className="index2"
                                      item
                                      sm={12}
                                      lg={11}
                                      md={11}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                      }}
                                    >
                                      <label>Cliente*</label>

                                      <Async
                                        //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                                        loadOptions={
                                          debouncedLoadOptionsClientes
                                        }
                                        cacheOptions
                                        isClearable={true}
                                        menuPortalTarget={document.body}
                                        noOptionsMessage={() =>
                                          'Nenhuma opção encontrada'
                                        }
                                        placeholder="Clientes"
                                        styles={{
                                          menuPortal: base => ({
                                            ...base,
                                            zIndex: 16,
                                          }),
                                          container: base => ({
                                            ...base,
                                            minWidth: '8rem',
                                          }),
                                        }}
                                        value={{
                                          label: nomeCliente ? nomeCliente : '',
                                          value: idCliente ? idCliente : '',
                                        }}
                                        onSelect={val => {
                                          if (val.length > 1) {
                                            setIdCliente(val.value);
                                          }
                                        }}
                                        onChange={value => {
                                          const valor =
                                            value === null ? '' : value.value;
                                          if (valor > 1) {
                                            setIdCliente(valor);
                                            setNomeCliente(value.labelshow);
                                            setVendedorP(
                                              value.dados.vendedorPadrao
                                                ? value.dados.vendedorPadrao
                                                    .nome
                                                : '',
                                            );
                                            setIdVendedorP(
                                              value.dados.vendedorPadrao
                                                ? value.dados.vendedorPadrao.id
                                                : '',
                                            );
                                            getTranspData(valor);
                                          } else {
                                            setIdCliente('');
                                            setNomeCliente('');
                                            setVendedorP('');
                                            setIdCliente('');
                                            setIdVendedorP('');
                                          }
                                        }}
                                      />
                                    </Grid>
                                    {idCliente && (
                                      <Grid item xs={1} sm={1} md={1} lg={1}>
                                        <label></label>
                                        <InfoClient
                                          parentData={setDataParent}
                                          data={idCliente}
                                          prazo={prz_pag}
                                          zIndex="999"
                                        />
                                      </Grid>
                                    )}

                                    <Grid
                                      className="index2"
                                      item
                                      sm={12}
                                      lg={12}
                                      md={12}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                      }}
                                    >
                                      <label>Vendedor*</label>
                                      <Autocomplete
                                        items={autoVendedor}
                                        wrapperStyle={{
                                          position: 'relative',
                                          zIndex: '15',
                                          display: 'inline-block',
                                        }}
                                        shouldItemRender={(item, value) =>
                                          item.NOME.toLowerCase().indexOf(
                                            value.toLowerCase(),
                                          ) > -1
                                        }
                                        getItemValue={item => {
                                          setIdVendedorP(item.NUMCAD1);
                                          return item.NOME;
                                        }}
                                        renderItem={(item, isHighlighted) => (
                                          <div
                                            key={item.NUMCAD1}
                                            style={{
                                              background: isHighlighted
                                                ? 'lightgray'
                                                : 'white',
                                              width: '100%',
                                            }}
                                          >
                                            {item.NOME}
                                          </div>
                                        )}
                                        value={vendedorP}
                                        defaultValue={vendedorP}
                                        onChange={e =>
                                          setVendedorP(e.target.value)
                                        }
                                        onSelect={val => {
                                          setVendedorP(val);
                                          setVendedorNomeForm(val);
                                        }}
                                      />
                                    </Grid>
                                    {
                                      //[08:52, 26/04/2022] Fabiano Tempesta: Só permita que ele selecione EMITENTE se o valor for maior que R$ 2.500,00
                                      //[08:52, 26/04/2022] Fabiano Tempesta: É que pode acontecer do cliente exigir uma transportadora própria (frete DESTINATÁRIO) independente do valor
                                    }
                                    <Grid item xs={12} sm={12} lg={4}>
                                      <div className="input">
                                        <label>Tipo Frete</label>

                                        <select
                                          id="tipo_frete"
                                          name="tipo_frete"
                                          style={{
                                            minWidth: '9rem',
                                            //padding: "1.125rem 0.15rem",
                                            minHeight:
                                              'calc(2.0em + 0.65rem + 2px)',
                                            fontSize: '14px',
                                          }}
                                          defaultValue={frete}
                                          onChange={e => {
                                            if (
                                              vl_tot_pedido < 2500 &&
                                              e.target.value == 'EMITENTE'
                                            ) {
                                              e.target.value = 'DESTINATARIO';
                                              toast.error(
                                                'Pedidos até R$ 2.500,00 (FOB).',
                                              );
                                              setFrete(e.target.value);
                                            } else {
                                              setFrete(e.target.value);
                                            }
                                          }}
                                        >
                                          <option></option>
                                          <option>EMITENTE</option>
                                          <option>DESTINATARIO</option>
                                        </select>
                                      </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} lg={2}>
                                      <div className="input">
                                        <label>Prioridade</label>

                                        <Select
                                          //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                                          options={prioridades}
                                          isClearable={false}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: base => ({
                                              ...base,
                                              zIndex: 15,
                                            }),
                                          }}
                                          defaultValue={{
                                            value: prioridadeEscolhida,
                                            label: prioridadeEscolhida,
                                          }}
                                          onSelect={e => {
                                            setPrioridadeEscolhida(
                                              e.target.value,
                                            );
                                          }}
                                          onChange={value => {
                                            setPrioridadeEscolhida(value.value);
                                          }}
                                        />
                                      </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} lg={6}>
                                      <div className="input">
                                        <label>Forma de Pagamento</label>
                                        <Select
                                          //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                                          options={forma_pagamento_default}
                                          isClearable={false}
                                          isDisabled={false}
                                          //isOptionDisabled={fieldsReadonly? {value: prioridade_default, label: prioridade_default}:false}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: base => ({
                                              ...base,
                                              zIndex: 14,
                                            }),
                                            container: base => ({
                                              ...base,
                                              minWidth: '8rem',
                                            }),
                                          }}
                                          onSelect={e => {
                                            setPaymentForm(e.target.value);
                                            setPrzPag('');
                                            setParcelasCartao('');
                                            setVlParcelas('');
                                            getPaymentData('');
                                         
                                          }}
                                          onChange={e => {
                                            setPaymentForm(e.value);
                                            setPrzPag('');
                                            setParcelasCartao('');
                                            setVlParcelas('');
                                            getPaymentData('');

                                          }}
                                        />
                                      </div>
                                    </Grid>
                                    {paymentForm != 'CARTÃO DE CRÉDITO' ? (
                                      <>
                                        <Grid item xs={12} sm={12} lg={4}>
                                          <div className="input">
                                            <label>Prazo Pagamento</label>
                                            <input
                                              name="prazo_pagamento"
                                              type="text"
                                              ref={refInput}
                                              value={paymentForm==''?'': refInput?.current?.value}
                                              onBlur={e => {
                                                getPaymentData(e.target.value);
                                              }}
                                              onKeyDown={e => {
                                                setVlParcelas('');
                                              }}
                                              onChange={e => e.target.value}
                                              //defaultValue={prz_pag}
                                              required="required"
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={3}>
                                          <div className="input">
                                            <label>Prazo médio</label>
                                            <input
                                              name="prz_medio"
                                              type="text"
                                              value={prz_medio}
                                              required="required"
                                            />
                                          </div>
                                        </Grid>
                                      </>
                                    ) : (
                                      <>
                                        <Grid item xs={12} sm={12} lg={6}>
                                          <div className="input">
                                            <label>Qtd Parcelas</label>
                                            <Select
                                              //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                                              options={qtd_parcelas_default}
                                              isClearable={false}
                                              isDisabled={false}
                                              //isOptionDisabled={fieldsReadonly? {value: prioridade_default, label: prioridade_default}:false}
                                              menuPortalTarget={document.body}
                                              styles={{
                                                menuPortal: base => ({
                                                  ...base,
                                                  zIndex: 14,
                                                }),
                                                container: base => ({
                                                  ...base,
                                                  minWidth: '8rem',
                                                }),
                                              }}
                                              onSelect={e => {
                                                setParcelasCartao(
                                                  e.target.value,
                                                );
                                                setQtdParcelas(e.target.value);
                                              }}
                                              onChange={e => {
                                                setParcelasCartao(e.value);
                                                setQtdParcelas(e.value);
                                              }}
                                            />
                                          </div>
                                        </Grid>
                                      </>
                                    )}
                                    <Grid item xs={12} sm={12} lg={3}>
                                      <div className="input">
                                        <label>Valor Parcelas</label>
                                        <input
                                          name="vl_parcelas"
                                          value={vlparcelas}
                                          type="text"
                                          required="required"
                                        />
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={2}>
                                      <ParcelNumber paymentTerm={qtdparcelas} />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>

                            <CardHeader
                              className={classes.header}
                              component={Typography}
                              title={'DEFINIÇÕES DE TRANSPORTE'}
                            />
                            <CardContent>
                              <Grid
                                container
                                spacing={2}
                                style={{ width: '100%', background: 'white' }}
                              >
                                <Grid item xs={12} lg={12} md={12}>
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{ width: '100%' }}
                                  >
                                    <Grid
                                      className="index2"
                                      item
                                      sm={12}
                                      lg={12}
                                      md={12}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                      }}
                                    >
                                      <label>Transportadora*</label>

                                      <Async
                                        //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                                        loadOptions={debouncedLoadOptionsTransp}
                                        cacheOptions
                                        isClearable={true}
                                        menuPortalTarget={document.body}
                                        noOptionsMessage={() =>
                                          'Nenhuma opção encontrada'
                                        }
                                        placeholder="Transportadora"
                                        value={{
                                          label: nomeTransp ? nomeTransp : '',
                                          value: idTransp ? idTransp : '',
                                        }}
                                        onSelect={val => {
                                          if (val.length > 1) {
                                            setIdTransp(val.value);
                                          }
                                        }}
                                        onChange={value => {
                                          const valor =
                                            value === null ? '' : value.value;
                                          if (valor > 1) {
                                            setIdTransp(valor);
                                            setNomeTransp(value.labelshow);
                                          } else {
                                            setIdTransp('');
                                            setNomeTransp('');
                                          }
                                        }}
                                      />
                                    </Grid>

                                    <Grid
                                      className="index2"
                                      item
                                      sm={12}
                                      lg={12}
                                      md={12}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                      }}
                                    >
                                      {' '}
                                      <label>Redespacho*</label>
                                      <Async
                                        //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                                        loadOptions={debouncedLoadOptionsTransp}
                                        cacheOptions
                                        isClearable={true}
                                        menuPortalTarget={document.body}
                                        noOptionsMessage={() =>
                                          'Nenhuma opção encontrada'
                                        }
                                        placeholder="Transportadora"
                                        value={{
                                          label: nomeTranspRedespacho
                                            ? nomeTranspRedespacho
                                            : '',
                                          value: idTranspRedespacho
                                            ? idTranspRedespacho
                                            : '',
                                        }}
                                        onSelect={val => {
                                          if (val.length > 1) {
                                            setIdTranspRedespacho(val.value);
                                          }
                                        }}
                                        onChange={value => {
                                          const valor =
                                            value === null ? '' : value.value;
                                          if (valor > 1) {
                                            setIdTranspRedespacho(valor);
                                            setNomeTranspRedespacho(
                                              value.labelshow,
                                            );
                                          } else {
                                            setIdTranspRedespacho('');
                                            setNomeTranspRedespacho('');
                                          }
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} lg={7} md={7}>
                          <Card className={classes.card}>
                            <CardHeader
                              className={classes.header}
                              component={Typography}
                              title={'PEDIDOS'}
                            />
                            <CardContent>
                              <Grid
                                container
                                spacing={0}
                                style={{
                                  width: '100%',
                                  minHeight: '100%',
                                }}
                              >
                                <Grid
                                  className="index2"
                                  item
                                  sm={12}
                                  lg={12}
                                  md={12}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                  }}
                                >
                                  <DataTable
                                    rows={listCart}
                                    rowHead={headRequest}
                                    title={''}
                                    titleNoData={''}
                                    searchInput={false}
                                    load={loading}
                                    maxHeight={'45'}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12}>
                                  <label>Observações</label>

                                  <div className="input">
                                    <textarea
                                      rows="5"
                                      cols="40"
                                      name="obs"
                                      type="text"
                                      onChange={e => setObs(e.target.value)}
                                    />
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  lg={12}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <label>* Campos obrigatórios</label>
                                </Grid>

                                <ToastContainer />

                                <ButtonRequest
                                  bg="#ff5858"
                                  disabled={isRequest}
                                  onClick={cancelRequest}
                                >
                                  Cancelar
                                </ButtonRequest>

                                {(idCliente.length !== 0) &
                                (idVendedorP.length !== 0) &
                                (vlparcelas !== '') &
                                (paymentForm !=='')&
                                (prz_pag !== '' || parcelasCartao != '') ? (
                                  <ButtonRequest
                                    bg="#00acc1"
                                    disabled={isRequest}
                                    onClick={() => {
                                      orderView();
                                    }}
                                  >
                                    Visualizar Pedidos
                                  </ButtonRequest>
                                ) : (
                                  ''
                                )}
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </FormAuto>
                  </Typography>
                </div>
              </Pesquisa>

              <ToastContainer />
            </div>
          </FormAuto>
        </>
      );
    } else if (isFinishRequest) {
      return (
        <PaperStyle>
          <div className="div">
            <h1 style={{ marginTop: '18px' }}>Notificação do Cliente</h1>
            <FormAuto>
              <Grid container spacing={1}>
                <Grid className="index" item sm={12} lg={6} md={12}>
                  <label>Email de notificação</label>
                  <ReactMultiEmail
                    placeholder="Emails que receberam o pdf"
                    emails={emails}
                    onChange={_emails => {
                      setEmail(_emails);
                    }}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          {email}
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </Grid>
                <ButtonRequest
                  bg="#00acc1"
                  disabled={isRequest}
                  onClick={e => sendUserNotification(e)}
                >
                  Enviar Notificação
                </ButtonRequest>
              </Grid>
            </FormAuto>
          </div>

          <DataTable
            rows={listRequest}
            rowHead={rowHeadRequest}
            title={''}
            titleNoData={''}
            searchInput={false}
            load={loading}
          />

          <br />
          <br />
        </PaperStyle>
      );
    } else if (isViewRequest) {
      function ccyFormat(numero) {
        return (
          'R$ ' +
          numero
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+\,)/g, '$1.')
        );
      }

      function subtotal(items) {
        return items
          .map(({ VALOR_TOTAL }) => VALOR_TOTAL)
          .reduce((sum, i) => sum + i, 0);
      }

      function total() {
        var tot = 0;
        itemSendRequest.map(item => {
          tot = tot + subtotal(item.items);
        });
        return tot;
      }

      return (
        <PaperStyle>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCellPrimary colSpan="6" align="center">
                    Pedido(s) {' de: ' + itemSendRequest[0].clienteNome}
                  </StyledTableCellPrimary>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Cliente</StyledTableCell>
                  <StyledTableCell align="center">Vendedor</StyledTableCell>
                  <StyledTableCell align="center">
                    Transportadora
                  </StyledTableCell>
                  <StyledTableCell align="center">Redespacho</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow key={itemSendRequest[0].index}>
                  <StyledTableCell>{nomeCliente}</StyledTableCell>
                  <StyledTableCell align="left">
                    {itemSendRequest[0].vendedor_id + '-' + vendedorP}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {itemSendRequest[0].transportadora +
                      '-' +
                      itemSendRequest[0].transportadora_nome}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {itemSendRequest[0].redespacho +
                      '-' +
                      itemSendRequest[0].redespacho_nome}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <br></br>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Código</StyledTableCell>
                  <StyledTableCell align="left">Item</StyledTableCell>
                  <StyledTableCell align="left">Grade</StyledTableCell>
                  <StyledTableCell align="right">Empresa</StyledTableCell>
                  <StyledTableCell align="right">Programação</StyledTableCell>
                  <StyledTableCell align="right">Quantidade</StyledTableCell>
                  <StyledTableCell align="right">
                    Valor Unitário
                  </StyledTableCell>
                  <StyledTableCell align="right">Valor Total</StyledTableCell>
                </TableRow>
              </TableHead>

              {itemSendRequest.map((item, index) => (
                <TableBody>
                  {item.items.map(row => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell>{row.ITEM_ID}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.ITEM_NOME}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.ITEM_GRADE}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.EMPRESA_APELIDO}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.TIPO_VENDA}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.QUANTIDADE + row.ITEM_UNIDADE}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {ccyFormat(row.VALOR_UNITARIO)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {ccyFormat(row.VALOR_TOTAL)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}

                  <TableRow>
                    <StyledTableCell rowSpan={6} colSpan={6} align="right" />
                    <StyledTableCell colSpan={1} align="right">
                      Subtotal
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {ccyFormat(subtotal(item.items))}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell colSpan={1} align="right">
                      Prazo de pagamento
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {paymentForm== 'CARTÃO DE CRÉDITO' ? item.prazo_pagamento + 'x' : item.prazo_pagamento}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              ))}

              <TableRow>
                <StyledTableCell colSpan={7} align="right">
                  Total
                </StyledTableCell>
                <StyledTableCell align="right">
                  {ccyFormat(total())}
                </StyledTableCell>
              </TableRow>
            </Table>
          </TableContainer>
          <br />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCell rowSpan="6" align="center">
                    Observações
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow key={itemSendRequest[0]}>
                  <StyledTableCell align="left">
                    <textarea style={{ width: '100%', height: '100px' }}>
                      {itemSendRequest[0].observacoes}
                    </textarea>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <ButtonRequest
            bg="#00acc1"
            disabled={isRequest}
            onClick={orderConfirmation}
          >
            Confirmar Pedido
          </ButtonRequest>
          <ButtonRequest bg="#00acc1" onClick={orderViewCancel}>
            Voltar
          </ButtonRequest>
        </PaperStyle>
      );
    }
  }
}

FinalizaPedido.propTypes = {
  itemCart: PropTypes.arrayOf(PropTypes.object).isRequired,
  confirma: PropTypes.bool,
  Transp: PropTypes.arrayOf(PropTypes.object).isRequired,
  Clients: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default connect()(FinalizaPedido);
