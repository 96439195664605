/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector, connect, useDispatch } from 'react-redux';
import DataTable from 'components/Table/Table.js';
import ItemProdutos from 'components/Pedidos/ModalItemProdutos';
import { ToastContainer, toast } from 'react-toastify';

import { Form, ButtonStyled, ButtonRequest, Input, Select } from './styles';
import axios from 'axios';
import moment from 'moment';
import history from '../../services/history';
import FinalizaPedido from '../Produtos/FinalizaPedido';
import { FormAuto } from '../Produtos/stylesFinaliza';
import { API } from '../../config/api';

// Função para criar OBJ dos Pedidos
function createRequest(view, busines, typeSales, dateProg, VlTot) {
  return {
    view,
    busines,
    typeSales,
    dateProg,
    VlTot,
  };
}

const headRequest = [
  {
    title: '',
    field: 'view',
    cellStyle: {
      padding: '2px',
      margin: '2px',
      textAlign: 'center',
    },
  },

  {
    title: 'Empresa',
    field: 'busines',
  },
  {
    title: 'Programação',
    field: 'typeSales',
  },
  {
    title: 'Dt Programação',
    field: 'dateProg',
  },
  {
    title: 'Valor',
    field: 'VlTot',
    headerStyle: {
      textAlign: 'right',
      width: '400px',
      maxWidth: '200px',
    },
    cellStyle: {
      fontSize: '10px',
      whiteSpace: 'nowrap',
      textAlign: 'right',
      width: '25px',
      maxWidth: '85px',
    },
  },
  {},
];

function Carrinho() {
  const [lista, setLista] = useState([]);
  const [listCart, setListCart] = useState([]);
  const [loading, setLloading] = useState(true);
  const [isConfirmRequest, setIsConfirmRequest] = useState(false);
  const [ConfirmPedido, setConfirmPedido] = useState(false);
  const [autoTransp, setTransp] = useState([]);

  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  const userId = sessionStorage.getItem('id');

  const isRequest = listCart.length === 0;
  useEffect(() => {
    const loadCarriers = async () => {
      try {
        const responseTransp = await axios.get(`${API.transportadoras}`, {
          headers: {
            'x-access-token': token,
          },
        });

        // response.data.data.map(item => {
        //   return { name: item.NOME, id: item.NUMCAD1 };
        // });

        setTransp(
          responseTransp.data.data.map(item => {
            return { name: item.NOME, id: item.NUMCAD1 };
          }),
        );
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error('Sua sessão expirou, favor efetuar o login');
          sessionStorage.clear();
        } else {
          toast.error('Erro ao carregar lista de transportadoras');
        }
      }
    };
    confirmRequest();
    loadCarriers();
  }, []);

  async function confirmRequest() {
    try {
      setIsConfirmRequest(true);

      const response = await axios.get(`${API.carrinho}?email=${email}`, {
        headers: {
          'x-access-token': token,
        },
      });

      for (let index = 0; index < response.data.data.length; index++) {
        for (let idx = 0; idx < response.data.data[index].length; idx++) {
          const element = response.data.data[index][idx];
        }
      }

      const idNumberRequest = [];
      var totalGPedido = 0;
      for (let index = 0; index < response.data.data.length; index++) {
        const respedidos = await axios.get(`${API.numeropedido_erp}`, {
          headers: {
            'x-access-token': token,
          },
        });

        //    console.log(">>> response:", respedidos.data.data);

        const listProducts = [];
        let companyName = '';
        let dateProgram = '';
        let tipoVenda = '';
        let totalPedido = 0;

        for (let idx = 0; idx < response.data.data[index].length; idx++) {
          listProducts.push(response.data.data[index][idx]);
          totalPedido =
            totalPedido +
            response.data.data[index][idx].QUANTIDADE *
              response.data.data[index][idx].VALOR_UNITARIO;
          if (companyName === '') {
            companyName = response.data.data[index][idx].EMPRESA_APELIDO;
            dateProgram = response.data.data[index][idx].DATA_PROGRAMACAO;
            tipoVenda = response.data.data[index][idx].TIPO_VENDA;
          }
        }
        totalGPedido = totalGPedido + totalPedido;
        const row = createRequest(
          <ItemProdutos itemCart={listProducts} />,

          <span>{companyName}</span>,
          <span>{tipoVenda}</span>,
          <span>{moment(dateProgram).format('DD/MM/YYYY')}</span>,
          <span>
            {totalPedido.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>,
          userId,
        );

        idNumberRequest.push(row);
      }
      if (idNumberRequest.length > 1) {
        idNumberRequest.push(
          createRequest(
            '',
            '',
            '',
            <b>Total do Pedido:</b>,
            totalGPedido.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
          ),
        );
      }
      setLloading(false);
      setListCart(idNumberRequest);
    } catch (error) {
      setLloading(false);
      return false;
    }
  }

  async function cancelRequest() {
    setListCart([]);
    setLista([]);

    const handleDell = async () => {
      var answer = window.confirm(
        'Tem certeza que deseja excluir esse carrinho ?',
      );
      if (answer) {
        try {
          await axios.delete(`${API.carrinho_removecart}?email=${email}`, {
            headers: {
              'x-access-token': token,
            },
          });
          toast.success('Carrinho excluido com sucesso');
          window.location.reload();
        } catch (err) {
          toast.error('Ocorreu algum erro!');
        }
      }
    };
    handleDell();
  }
  async function addProdutosRequest() {
    history.push('/admin/produtos');
  }

  function confirmaPedido(e) {
    e.preventDefault();

    setConfirmPedido(true);
    setIsConfirmRequest(true);
  }

  // Inicia o processo de confirmação de pedido
  return (
    <div>
      {ConfirmPedido ? (
        <>
          <FormAuto>
            <FinalizaPedido itemCart={listCart} Transp={autoTransp} />
          </FormAuto>
        </>
      ) : (
        <>
          <DataTable
            rows={listCart}
            rowHead={headRequest}
            title={'Carrinho'}
            titleNoData={''}
            searchInput={false}
            sort={false}
            load={loading}
          />
          <ToastContainer />

          <ButtonRequest disabled={isRequest} onClick={cancelRequest}>
            Limpar Carrinho
          </ButtonRequest>

          <ButtonRequest
            style={{
              marginTop: 10,
              marginLeft: 10,
              padding: 10,
              border: 0,
              backgroundColor: '#00acc1',
              borderRadius: 5,
              color: '#fff',
              fontSize: 16,
              fontWeight: 'bold',
              transition: '0.4s',
              cursor: 'pointer',
            }}
            // disabled={isRequest}
            onClick={addProdutosRequest}
          >
            Produtos
          </ButtonRequest>
          {!isRequest ? (
            <button
              style={{
                marginTop: 10,
                marginLeft: 10,
                padding: 10,
                width: 200,
                border: 0,
                backgroundColor: '#00acc1',
                borderRadius: 5,
                color: '#fff',
                fontSize: 16,
                fontWeight: 'bold',
                transition: '0.4s',
                cursor: 'pointer',
              }}
              disabled={isRequest}
              onClick={confirmaPedido}
            >
              Finalizar Pedido
            </button>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
}

export default connect()(Carrinho);
