import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PropTypes from "prop-types";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import DataTable from "components/Table/Table";
import { Form } from "./styles";
import moment from "moment";
import {API} from "../../../config/api"
import axios from "axios";




function createData(
  codigo,
  nome,
  grade,
  quantidade,
  valorUnitario,
  total
) {
  return {
    codigo,
    nome,
    grade,
    quantidade,
    valorUnitario,
    total
  };
}

const rowHead = [
  {
    title: "Código",
    field: "codigo",
    cellStyle: {
      fontSize: "10px",
      whiteSpace: "nowrap",
      textAlign: "left"
    }
  },
  {
    title: "Nome",
    field: "nome",
    cellStyle: {
      fontSize: "10px",
      whiteSpace: "nowrap",
      textAlign: "left"
    }
  },
  {
    title: "Grade",
    field: "grade",
    cellStyle: {
      fontSize: "10px",
      whiteSpace: "nowrap",
      textAlign: "right"
    }
  },
  {
    title: "Quantidade",
    field: "quantidade",
    headerStyle: {
      width: 100,
      maxWidth: 100,
      minWidth: 100,
      textAlign:"center"
    },
    cellStyle: {
      fontSize: "10px",
      whiteSpace: "nowrap",
      textAlign: "right"
    }
  },
  {
    title: "Valor unitário",
    field: "valorUnitario",
    headerStyle: {
      width: 82,
      maxWidth: 82,
      minWidth: 82,
    },
    cellStyle: {
      fontSize: "10px",
      whiteSpace: "nowrap",
      textAlign: "left"
    }
  },
  {
    title: "Valor",
    field: "total",
    headerStyle: {
      width: 72,
      maxWidth: 72,
      minWidth: 72,
    },
    cellStyle: {
      fontSize: "10px",
      whiteSpace: "nowrap",
      textAlign: "left"
    }
  }
];

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "80%",
    height: "80%",
    overflow: "scroll",
    minWidth: "50%"
  },
  button: {
    border: 0,
    borderRadius: "20px",
    backgroundColor: "transparent",
    color: "#00acc1",
    padding: "5px",
    cursor: "pointer"
  },
  text: {
    padding: "10px",
    color: "#656464"
  }
}));

export default function ModalPedidosSQL({ data }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const perfil = sessionStorage.getItem("perfil");
  const token = sessionStorage.getItem("token");
  const email = sessionStorage.getItem("email");
  const [rowList, setrowList] = useState([]);
  const [loading, setLloading] = useState(false);


  const handleOpen = () => {
    setOpen(true);
  };

  async function handleReqItens(NUMERO_SISTEMA) {
    try {
      setLloading(true);

      const response = await axios.get(`${API.pedidos}/${NUMERO_SISTEMA}/?email=${email}`, {
        headers: {
          'x-access-token': token,
        }
      });
      const itens = response.data?response.data.data[0].itens:'';
      if(itens){
        console.log('entrou'+NUMERO_SISTEMA);

      const  rowList = itens
          ? itens.map(item => {
              const { nome, codigo, grade } = item.item;
              console.log(item.item);
              const { quantidade, unidadeCodigo, valorUnitario } = item;
              const total = (quantidade * valorUnitario).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL"
              });
              (quantidade * valorUnitario).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL"
              });
              const row = createData(
                codigo,
                nome,
                grade,
                quantidade+' '+ unidadeCodigo,
                valorUnitario.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }),
                total
              );
      
              return row;
            })
          : [{ error: "Não encontrado" }];
          console.log(rowList);
          setrowList(rowList);
          setLloading(false);
      }

      
    } catch (err) {
      setLloading(false);

      //  toast.error("Houve um erro ao listar contatos.");
    }
  }

  const {
    APELIDO,
    CLIENTE_APELIDO,
    CLIENTE_CGC,
    CLIENTE_COD,
    NUMERO_SISTEMA,
    STATUS,
    PEDIDO_PRAZO_PAGAMENTO,
    PEDIDO_EMISSAO,
    PEDIDO_PREVISAO,
    PEDIDO_REFERENCIA,
    PEDIDO_VALOR,
    PEDIDO_OBSERVACAO,
    GERENTE_NOME,
    NOTA_FISCAL_NUMERO,
    NOTA_FISCAL_EMISSAO,
    NOTA_FISCAL_VALOR,
    ROMANEIO_NUMERO,
    TRANSPORTADORA_NOME,
    VENDEDOR_APELIDO
  } = data;
  moment(PEDIDO_EMISSAO.slice(0,10)).format("DD/MM/YYYY");
  moment(PEDIDO_PREVISAO.slice(0,10)).format("DD/MM/YYYY");
 const observacoes = ''
  const handleClose = () => {
    setOpen(false);
  };

  const { register } = useForm();

  return (
    <div>
      <button className={classes.button} type="button" onClick={handleOpen}>
        <VisibilityIcon />
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Tabs>
              <TabList>
                <Tab>Geral</Tab>
                <Tab>Observação</Tab>
               <Tab onClick={() => handleReqItens(NUMERO_SISTEMA)}>Itens</Tab>
              </TabList>

              <TabPanel>
                <Form>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Pedido número</label>
                        <input
                          readOnly
                          name="numSistema"
                          type="text"
                          ref={register}
                          value={NUMERO_SISTEMA ? NUMERO_SISTEMA : ""}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Referência</label>
                        <input
                          readOnly
                          name="referencia"
                          type="text"
                          ref={register}
                          value={PEDIDO_REFERENCIA? PEDIDO_REFERENCIA : ""}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Valor</label>
                        <input
                          readOnly
                          name="valor"
                          type="text"
                          ref={register}
                          value={PEDIDO_VALOR ? PEDIDO_VALOR.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }) : ""}
                        />
                      </div>
                    </Grid>
                  


                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Empresa</label>
                        <input
                          readOnly
                          name="empresa"
                          type="text"
                          ref={register}
                          value={APELIDO ? APELIDO : ""}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Status</label>
                        <input
                          readOnly
                          name="status"
                          type="text"
                          ref={register}
                          value={STATUS ? STATUS : ""}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Prazo de pagamento </label>
                        <input
                          readOnly
                          name="prazo"
                          type="text"
                          ref={register}
                          value={PEDIDO_PRAZO_PAGAMENTO ? PEDIDO_PRAZO_PAGAMENTO : ""}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Cod Conta</label>
                        <input
                          readOnly
                          name="cod_conta"
                          type="text"
                          ref={register}
                          value={CLIENTE_COD ? CLIENTE_COD : ""}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Conta</label>
                        <input
                          readOnly
                          name="conta"
                          type="text"
                          ref={register}
                          value={CLIENTE_APELIDO ? CLIENTE_APELIDO : ""}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Conta CNPJ</label>
                        <input
                          readOnly
                          name="conta_cnpj"
                          type="text"
                          ref={register}
                          value={CLIENTE_CGC ? CLIENTE_CGC : ""}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Vendedor</label>
                        <input
                          readOnly
                          name="vendedor"
                          type="text"
                          ref={register}
                          value={VENDEDOR_APELIDO ? VENDEDOR_APELIDO : ""}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Emissão</label>
                        <input
                          readOnly
                          name="emissao"
                          type="text"
                          ref={register}
                          value={PEDIDO_EMISSAO ?   moment(PEDIDO_EMISSAO.slice(0,10)).format("DD/MM/YYYY") : ""}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Previsão</label>
                        <input
                          readOnly
                          name="previsao"
                          type="text"
                          ref={register}
                          value={PEDIDO_PREVISAO ?  moment(PEDIDO_PREVISAO.slice(0,10)).format("DD/MM/YYYY") : ""}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Romaneio</label>
                        <input
                          readOnly
                          name="romaneio_numero"
                          type="text"
                          ref={register}
                          value={ROMANEIO_NUMERO ? ROMANEIO_NUMERO : ""}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Transportadora</label>
                        <input
                          readOnly
                          name="transportadora"
                          type="text"
                          ref={register}
                          value={TRANSPORTADORA_NOME ? TRANSPORTADORA_NOME : ""}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Nota Fiscal número</label>
                        <input
                          readOnly
                          name="nf_numero"
                          type="text"
                          ref={register}
                          value={NOTA_FISCAL_NUMERO ? NOTA_FISCAL_NUMERO : ""}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Nota Fiscal emissão</label>
                        <input
                          readOnly
                          name="nf_emissao"
                          type="text"
                          ref={register}
                          value={NOTA_FISCAL_EMISSAO ? moment(NOTA_FISCAL_EMISSAO.slice(0,10)).format("DD/MM/YYYY") : ""}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Nota Fiscal valor</label>
                        <input
                          readOnly
                          name="nf_vl"
                          type="text"
                          ref={register}
                          value={NOTA_FISCAL_VALOR ? NOTA_FISCAL_VALOR.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }) : ""}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={6}>
                      <div className="input">
                        <label>Supervisor</label>
                        <input
                          readOnly
                          name="supervisor"
                          type="text"
                          ref={register}
                          value={GERENTE_NOME ? GERENTE_NOME : ""}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              </TabPanel>
              <TabPanel>
                <Form>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} lg={12}>
                      <div className="input">
                        <label>Observações</label>
                        <textarea
                          className={classes.text}
                          readOnly
                          name="obs"
                          type="text"
                          rows={8}
                          ref={register}
                          value={
                            PEDIDO_OBSERVACAO
                              ? PEDIDO_OBSERVACAO
                              : "Nenhuma observação foi feita"
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              </TabPanel>
              <TabPanel>
                <DataTable rows={rowList} rowHead={rowHead} title={"Itens"}   load={loading}/>
              </TabPanel>
            </Tabs>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ModalPedidosSQL.propTypes = {
  data: PropTypes.object.isRequired
};
