/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import DataTable from 'components/Table/Table.js';
import Carrinho from 'components/CarrinhoModal';
import FinalizaPedido from './FinalizaPedido';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Pdf from '../../components/Produtos/PDF';
import PdfColecao from '../../components/Produtos/PDFCOLECAO';
import ImagesProducts from '../../components/Produtos/Carousel';
import { makeStyles } from '@material-ui/core/styles';
import { Form, ButtonStyled, Input, ContainerSearch } from './styles';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import axios from 'axios';
import { FormAuto } from './stylesFinaliza';
import { API } from '../../config/api';
import Paper from '@material-ui/core/Paper';
import Select from 'react-select';
import Async from 'react-select/async';
import debounce from 'debounce-promise';
import { update } from 'store/modules/pedidos/actions';
import { TablePagination, Typography, Divider } from '@material-ui/core';
import moment from 'moment';


// Função para criar OBJ dos produtos
function createData(
  exc,
  colecao,
  img,
  ITEM_CODIGO,
  ITEM_NOME,
  ITEM_GRADE,
  PE_OU_PROG,
  ITEM_PREVISAO,
  ITEM_SALDO,
  inputQuantidade,
  inputValor,
) {
  return {
    exc,
    colecao,
    img,
    ITEM_CODIGO,
    ITEM_NOME,
    ITEM_GRADE,
    PE_OU_PROG,
    ITEM_PREVISAO,
    ITEM_SALDO,
    inputQuantidade,
    inputValor,
  };
}

const rowHead = [
  {
    title: 'Ficha',
    field: 'exc',
    headerStyle: {
      width: 3,
      maxWidth: 3,
    },
    cellStyle: {
      fontSize: '3px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
  },
  {
    title: 'Catálogo',
    field: 'colecao',
    headerStyle: {
      width: 72,
      maxWidth: 72,
      minWidth: 72,
    },
    cellStyle: {
      fontSize: '30px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
  },
  {
    title: 'Coleção',
    field: 'img',
    headerStyle: {
      width: 3,
      maxWidth: 3,
    },
    cellStyle: {
      fontSize: '3px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
  },

  {
    title: 'Código',
    field: 'ITEM_CODIGO',
    headerStyle: {
      width: 15,
      maxWidth: 20,
    },
    cellStyle: {
      fontSize: '10px',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'Nome',
    field: 'ITEM_NOME',
    headerStyle: {
      width: 45,
      maxWidth: 60,
    },
    cellStyle: {
      fontSize: '10px',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'Grade',
    field: 'ITEM_GRADE',
    headerStyle: {
      width: 25,
      maxWidth: 30,
    },
    cellStyle: {
      fontSize: '10px',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'Prog',
    field: 'PE_OU_PROG',
    headerStyle: {
      width: 25,
      maxWidth: 25,
      minWidth: 25,
    },
    cellStyle: {
      fontSize: '10px',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'Previsão',
    field: 'ITEM_PREVISAO',
    headerStyle: {
      width: 25,
      maxWidth: 25,
      minWidth: 25,
    },
    cellStyle: {
      fontSize: '10px',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'Disponível',
    field: 'ITEM_SALDO',
    headerStyle: {
      textAlign: 'right',
      width: 15,
      headerStyle: {
        width: 10,
        maxWidth: 10,
        padding: 1,
      },
      headerStyle: {
        width: 10,
        maxWidth: 10,
        padding: 1,
      },
      maxWidth: 20,
    },
    cellStyle: {
      fontSize: '10px',
      whiteSpace: 'nowrap',
      textAlign: 'right',
    },
  },

  {
    title: 'Quantidade',
    field: 'inputQuantidade',
    headerStyle: {
      width: 15,
      maxWidth: 20,
      textAlign: 'center',
    },
    cellStyle: {
      fontSize: '10px',
      whiteSpace: 'nowrap',
    },
  },

  {
    title: 'Valor unitário',
    field: 'inputValor',
    headerStyle: {
      textAlign: 'center',
      width: 25,
      maxWidth: 25,
    },
    cellStyle: {
      fontSize: '10px',
      whiteSpace: 'nowrap',
    },
  },
];

function formatDecimal(numero) {
  return numero.replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, '$1.');
}
function Clientes() {
  const [isConfirmRequest, setIsConfirmRequest] = useState(false);
  const [isConfirmRequestEdit, setIsConfirmRequestEdit] = useState(false);
  const [valor_unitario_default, setValor_unitario_default] = useState(0);
  const [open, setOpen] = React.useState(false);


  const [lista, setLista] = useState([]);
  const [listCart, setListCart] = useState([]);
  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  const isRequest = listCart.length === 0;
  const [ConfirmPedido, setConfirmPedido] = useState(false);
  const [autoTransp, setTransp] = useState([]);
  const [autoCliente, setAutoCliente] = useState([]);
  const [autoVendedor, setAutoVendedor] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalEstoque, setTotalEstoque] = useState(0);
  const [totalEstoqueEsc, setTotalEstoqueEsc] = useState(0);

  const useStyles = makeStyles(theme => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    paper: {
      backgroundColor: theme.palette.background.paper,
      paddingTop: '5px',
    },
  }));
  const classes = useStyles();
  
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsConfirmRequestEdit(false);
  };

  useEffect(() => {
    const loadCarriers = async () => {
      try {
        const responseTransp = await axios.get(`${API.transportadoras}`, {
          headers: {
            'x-access-token': token,
          },
        });
       // console.log('transpor', responseTransp.data.data[0]);
        // response.data.data.map(item => {
        //   return { name: item.NOME, id: item.NUMCAD1 };
        // });

        setTransp(
          responseTransp.data.data.map(item => {
            return {
              name: item.APELIDO + '(' + item.CGC + ')',
              id: item.NUMCAD1,
            };
          }),
        );
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error('Sua sessão expirou, favor efetuar o login');
          sessionStorage.clear();
        } else {
          toast.error('Erro ao carregar lista de transportadoras');
        }
      }
    };

    /*
    async  function ConsultaTransp(id){
      try {
       // console.log('o id é '+idCliente);
        const response = await axios.get(
          `http://concept.salesbreath.com.br:3002/api/v1/clientes/transportadorasByClienteId/${id}`,
          {
            headers: {
              "x-access-token": token
            }
          }
        );
        return response.data.data;
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error("Sua sessão expirou, favor efetuar o login");
       //   sessionStorage.clear();
        } else {
    //      sessionStorage.clear();
  
          toast.error("Erro ao carregar lista de vendedores");
        }
      }
    };




    const loadClients = async () => {
      try {
        const response = await axios.get(
          `http://concept.salesbreath.com.br:3002/api/v1/clientes?email=${email}`,
          {
            headers: {
              "x-access-token": token
            }
          }
        );
        var list_clients=[];
        //list_clients.push(response.data.data);
        //console.log(response.data.data);
        for (let index = 0; index < response.data.data.length; index++) {  
        
          //list_clients.codigo         = response.data.data[index].id;
          
          const transpid   = await ConsultaTransp(response.data.data[index].id);
          //console.log(transpid[0].TRANSPORTADORA_ID);
          
          response.data.data[index].transportadora_id       = transpid[0].TRANSPORTADORA_ID;
          response.data.data[index].transportadora_apelido  = transpid[0].TRANSPORTADORA_APELIDO;
          response.data.data[index].transportadora_cnpj     = transpid[0].TRANSPORTADORA_CNPJ;
          response.data.data[index].redespacho_id           = transpid[0].REDESPACHO_ID;
          response.data.data[index].redespacho_apelido      = transpid[0].REDESPACHO_APELIDO;
          response.data.data[index].redespacho_cnpj         = transpid[0].REDESPACHO_CNPJ;

          list_clients.push(response.data.data[index])
          //console.log('bora ver');
          //console.log(response.data.data[index].id);
          //console.log(transpid);


          
         
        }
        console.log('mostrando posicao 0 ');
        console.log(list_clients);
        //list_new_client =[];





        setAutoCliente(list_clients);
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error("Sua sessão expirou, favor efetuar o login");
          sessionStorage.clear();
        } else {
          sessionStorage.clear();

          toast.error("Erro ao carregar lista de clientes");
        }
      }
    };

    const loadSales = async () => {
      try {
        const response = await axios.get(
          `http://concept.salesbreath.com.br:3002/api/v1/clientes/vendedores?email=${email}`,
          {
            headers: {
              "x-access-token": token
            }
          }
        );

        setAutoVendedor(response.data.data);
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error("Sua sessão expirou, favor efetuar o login");
          sessionStorage.clear();
        } else {
          sessionStorage.clear();

          toast.error("Erro ao carregar lista de vendedores");
        }
      }
    };
*/

    loadCarriers();
    //   loadClients();
    //   loadSales();
    getCartEndpoint();
  }, []);

  async function getCartEndpoint() {
    try {
      const response = await axios.get(`${API.carrinho}?email=${email}`, {
        headers: {
          'x-access-token': token,
        },
      });
      var newarray = [];

      response.data.data.map(item => {
        item.map(newitem => {
          const {
            ID,
            ITEM_ID,
            ITEM_NOME,
            ITEM_GRADE,
            EMPRESA_APELIDO,
            PE_OU_PROG,
            ITEM_SALDO,
            ITEM_UNIDADE,
            ITEM_VALOR_UNITARIO,
          } = newitem;
          newarray.push(newitem);
        });
      });
      setListCart(newarray);
      const totalEstoq = newarray
        ? newarray.reduce(
            (total, newarray) => total + Number(newarray.ITEM_SALDO),
            0,
          )
        : 0;
      //   setTotalEstoque(totalEstoq);

      const totalEstoqEscolhido = newarray
        ? newarray.reduce((total, newarray) => total + newarray.QUANTIDADE, 0)
        : 0;
      setTotalEstoqueEsc(totalEstoqEscolhido);
      //setLloading(false);
    } catch (error) {
     // console.log(error);
      if (error.response && error.response.status === 402) {
        //token expirado
        toast.error('Sua sessão expirou, favor efetuar o login');
        sessionStorage.clear();
      } else {
        //
      }

      return false;
    }
  }
  //desmenbrando o array

  const addToCartAmount = async (data, value) => {
    //console.log(data);

    const index =
      listCart.length > 0
        ? listCart.findIndex(i => i.ITEM_ID === data.ITEM_CODIGO)
        : -1;

    const itemBalance = await checkItemBalance(data);

    // console.log(">>> index ......:", index);
    // console.log(">>> value ......:", value);
    // console.log(">>> itemBalance.:", itemBalance);
    // console.log(">>> data .......:", data);

    const amountOld = data.QUANTIDADE;
    data.QUANTIDADE = value;
    data.ITEM_SALDO = (itemBalance - value).toFixed(2);

    if (index === -1) {
      if (value > 0) setListCart([...listCart, data]);
    } else {
      if (index > -1 && parseFloat(value) === 0.0) {
        data.ITEM_SALDO = itemBalance;
        setListCart(listCart.filter(i => i.ITEM_ID !== data.ITEM_CODIGO));
      } else {
        listCart[index].QUANTIDADE = data.QUANTIDADE;
        listCart[index].ITEM_SALDO = data.ITEM_SALDO;
        if (value > 0) setListCart(listCart);
      }
    }

    const dataSendCart = {
      email: email,
      item_id: data.ITEM_ID,
      codigo: data.ITEM_CODIGO,
      item_nome: data.ITEM_NOME,
      item_grade: data.ITEM_GRADE,
      item_unidade: data.ITEM_UNIDADE,
      empresa_id_erp: data.EMPRESA_ID,
      empresa_apelido: data.EMPRESA_APELIDO,
      data_programacao: data.ITEM_PREVISAO,
      quantidade: value,
      lista_preco_id: '1',
      valor_unitario: data.ITEM_VALOR_UNITARIO_ESCOLHIDO,
      valor_unitario_padrao: data.ITEM_VALOR_UNITARIO,
      tipo_venda: data.PE_OU_PROG,
      programacao_numero: data.PROGRAMACAO_ID,
      programacao_item_id: data.ITEM_ID,
    };
    // console.log("22222222222222222222");
   // console.log(dataSendCart);
    if (!isConfirmRequest && parseFloat(value) > 0.0) {
      const response = await insertItemCart(dataSendCart, amountOld);
      // console.log("retorno" + response);
      getCartEndpoint();
    }
  };

  const addToCartPrice = async (data, value) => {
    const index =
      listCart.length > 0
        ? listCart.findIndex(i => i.ITEM_ID === data.ITEM_CODIGO)
        : -1;

    //const valueValid = data.ITEM_VALOR_UNITARIO_ESCOLHIDO * 0.79;
    /*console.log(">>> index .......:", index);
    console.log(">>> value Item ..:", data.ITEM_VALOR_UNITARIO);
    console.log(">>> value Digita :", value);
    console.log(">>> valueValid ..:", valueValid);
    console.log("");
    console.log(">>> data ........:", data);
    console.log(">>> listCart ....:", listCart);
     */
    if (index === -1) {
      if (value > 0) {
        data.ITEM_VALOR_UNITARIO_ESCOLHIDO = value;
      }
      //   if (value !== data.ITEM_VALOR_UNITARIO_ESCOLHIDO) setListCart([...listCart, data]);
    } else {
      if (value > 0) {
        if (value !== data.ITEM_VALOR_UNITARIO) {
          listCart[index].ITEM_VALOR_UNITARIO_ESCOLHIDO = value;
          data.ITEM_VALOR_UNITARIO_ESCOLHIDO = value;

          const dataSendCart = {
            email: email,
            item_id: data.ITEM_ID,
            codigo: data.ITEM_CODIGO,
            item_nome: data.ITEM_NOME,
            item_grade: data.ITEM_GRADE,
            item_unidade: data.ITEM_UNIDADE,
            empresa_id_erp: data.EMPRESA_ID,
            empresa_apelido: data.EMPRESA_APELIDO,
            data_programacao: data.ITEM_PREVISAO,
            quantidade: data.QUANTIDADE,
            lista_preco_id: '1',
            valor_unitario: value,
            valor_unitario_padrao: data.ITEM_VALOR_UNITARIO,
            tipo_venda: data.PE_OU_PROG,
            programacao_numero: data.PROGRAMACAO_ID,
            programacao_item_id: data.ITEM_ID,
          };
          // console.log(dataSendCart);
          if (
            !isConfirmRequest &&
            parseFloat(value) > 0 &&
            data.QUANTIDADE > 0
          ) {
          //  console.log('entrou na alteração');
            const response = await insertItemCart(
              dataSendCart,
              data.QUANTIDADE,
            );
            // console.log("retorno" + response);
            getCartEndpoint();
          }

          //      setListCart(listCart);
        }
      } else {
        toast('O valor informado está abaixo do mínimo permitido.', {
          autoClose: 5000,
        });
      }
    }
  };

  const handleSearch = async e => {
    try {
      setLoading(true);
      if (e.produto !== '') {
        const response = await axios.get(
          `${API.produtos}?email=${email}&nome=${e.produto}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        const list = response.data.data;
      //  console.log(list);
        setLista([]);
        setLista(list);
        //console.log(list);
        const totalEstoq = list
          ? list.reduce((total, list) => total + Number(list.ITEM_SALDO), 0)
          : 0;
        setTotalEstoque(totalEstoq);
      } else {
        toast('Favor informar um produto para pesquisa', { autoClose: 5000 });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 402) {
        //token expirado
        toast.error('Sua sessão expirou, favor efetuar o login');
        sessionStorage.clear();
      } else if (error.response && error.response.status === 404) {
        //token expirado
        toast.error('Produto não encontrado.');
      } else {
        //
      }
    }
  };

  const checkItemBalance = async item => {
    try {
      const response = await axios.get(
        `${API.consultasaldo}?email=${email}&codigo=${item.ITEM_CODIGO}&empresa_id=${item.EMPRESA_ID}&tipo_venda=${item.PE_OU_PROG}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      return response.data ? response.data.data.saldo.toFixed(2) : -1;
    } catch (err) {
      toast.error('Ocorreu algum erro');
    }
  };

  const insertItemCart = async item => {
    try {
      const response = await axios.post(`${API.adicionaaocarrinho}`, item, {
        headers: {
          'x-access-token': token,
        },
      });
      return response.data.data;
    } catch (err) {
      // console.log(err);
      toast.error('Erro ao inserir.');
      return 'erro';
    }
  };

  // let mask = function(rawValue) {
  //   let array = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  //   let value = rawValue;
  //   let teste = String(350);
  //   let resultado = teste.substr(0, 1) + "." + teste.substr(1, 2);

  //   console.log("ValorMask", value);

  //   if (value.length == 3) {
  //     let valorTransfor = String(value) + 0;
  //     console.log("teste", valorTransfor);
  //   }
  //   switch (value.length) {
  //     case 3:
  //       array = [/[0-9]/, ".", /\d/, /\d/];
  //       break;
  //     case 5:
  //       array = [/[0-9]/, /\d/, ".", /\d/, /\d/];
  //       break;
  //     case 6:
  //       array = [/[0-9]/, /\d/, /\d/, ".", /\d/, /\d/];
  //       break;
  //     case 7:
  //       array = [/[0-9]/, /\d/, /\d/, /\d/, ".", /\d/, /\d/];
  //       break;
  //     case 8:
  //       array = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, ".", /\d/, /\d/];
  //   }

  //   return array;
  // };
  const onSubmit = (dados) => {
    //  console.log(dados.valor_default_unitario);
      const rowsList = lista
        ? lista.map((item) => {
            const {
              ITEM_ID,
              ITEM_CODIGO,
              ITEM_NOME,
              ITEM_GRADE,
              PE_OU_PROG,
              ITEM_SALDO,
              ITEM_UNIDADE,
              ITEM_VALOR_UNITARIO,
              ITEM_VALOR_UNITARIO_ESCOLHIDO,
              QUANTIDADE,
            } = item;
            let qtn = QUANTIDADE;
            if (item.QUANTIDADE < 0.1) {
              item.ITEM_VALOR_UNITARIO_ESCOLHIDO = dados.valor_default_unitario;
              //   item.ITEM_VALOR_UNITARIO=dados.valor_default_unitario;
            }
            //item.ITEM_VALOR_UNITARIO.value=Number(dados.valor_default_unitario);
          })
        : "";
  
      setLista([]);
      setLista(lista);
      setOpen(false); //Fecho o modal
    };
  const { handleSubmit, register } = useForm();
  // console.log(lista);
  const rowsList = lista
    ? lista.map(item => {
        const {
          ITEM_ID,
          ITEM_CODIGO,
          ITEM_NOME,
          ITEM_GRADE,
          MESTRE_CODIGO,
          MESTRE_ID,
          EMPRESA_APELIDO,
          PE_OU_PROG,
          ITEM_PREVISAO,
          ITEM_SALDO,
          ITEM_UNIDADE,
          ITEM_VALOR_UNITARIO,
          ITEM_VALOR_UNITARIO_ESCOLHIDO,
          QUANTIDADE,
        } = item;
        let qtn = QUANTIDADE;
      //  console.log(qtn);
        if (qtn > 0) {
          if (!String(qtn).match(/.00/) && String(qtn).length == 5) {
            if (qtn >= 100) {
              qtn += '0';
          //    console.log('sssss', qtn);
            }
          }
        }
        // if (qtn == 4) {
        //   if (!String(qtn).match(/.00/)) {
        //     qtn += "00";
        //     console.log("e", qtn);
        //   }
        // }
        let mask = function(rawValue) {
          /* let array = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
          let value = rawValue;
          switch (value.length) {
            case 3:
              array = [/[0-9]/, ".", /\d/, /\d/];
              break;
            case 4:
              if (qtn < 10) {
                array = [/[0-9]/, ".", /\d/, /\d/];
              }
              break;
            case 5:
              array = [/[0-9]/, /\d/, ".", /\d/, /\d/];
              break;
            case 6:
              array = [/[0-9]/, /\d/, /\d/, ".", /\d/, /\d/];
              break;
            case 7:
              array = [/[0-9]/, /\d/, /\d/, /\d/, ".", /\d/, /\d/];
              break;
            case 8:
              array = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, ".", /\d/, /\d/];
          }

          return array;*/
        };
        const row = createData(
          <Pdf num={ITEM_ID} />,
          <PdfColecao num={MESTRE_CODIGO} />,
          <ImagesProducts
            num={ITEM_CODIGO}
            mestre_id={MESTRE_ID}
            mestre_codigo={MESTRE_CODIGO}
          />,
          ITEM_CODIGO,
          ITEM_NOME,
          ITEM_GRADE,
          PE_OU_PROG,
          PE_OU_PROG==='P.E.'?'-':moment(ITEM_PREVISAO.slice(0, 10)).format('DD/MM/YYYY'),
          formatDecimal(ITEM_SALDO) + ' ' + ITEM_UNIDADE,
          /*
          <MaskedInput
            mask={mask}
            guide={false}
            onBlur={e => addToCartAmount(item, e.target.value)}
            defaultValue={qtn}
            type="text"
            name="quantidade"
            ref={register}
            style={{
              minWidth: "6rem",
              maxWidth: "6rem",
              textAlign: "right",
              height: "calc(1.5em + 0.75rem + 2px)",
              padding: "0.375rem 0.75rem",
              fontSize: "1rem",
              fontWeight: "400",
              lineHeight: "1.5",
              color: "#495057",
              backgroundColor: "#fff",
              backgroundClip: "padding-box",
              border: "1px solid #ced4da",
              position: "relative"
            }}
          />*/
          <Input
            type="number"
            name="quantidade"
            ref={register}
            defaultValue={QUANTIDADE}
            onBlur={e => addToCartAmount(item, e.target.value)}
            style={{ minWidth: '7rem', maxWidth: '7rem', textAlign: 'right' }}
          />,

          <Input
            type="number"
            name="valorUnitario"
            id="valorUnitario"
            defaultValue={ITEM_VALOR_UNITARIO_ESCOLHIDO}
            onBlur={e => addToCartPrice(item, e.target.value)}
            style={{ minWidth: '7rem', maxWidth: '7rem', textAlign: 'right' }}
          />,
          ITEM_VALOR_UNITARIO,
        );

        return row;
      })
    : [{ error: 'Não encontrado' }];

  async function cancelRequest() {
    setListCart([]);
    setLista([]);
    setIsConfirmRequest(false);
  }

  function confirmaPedido(e) {
    e.preventDefault();

    setConfirmPedido(true);
    setIsConfirmRequest(true);
  }
  // Inicia o processo de seleção e Produtos (quantidade e valor)
  return (
    <>
      <Form onSubmit={handleSubmit(handleSearch)}>
        <div className="lineForm">
          {!isConfirmRequest && (
            <>
              <input
                name="produto"
                type="text"
                ref={register}
                placeholder="Digite o nome do produto"
              />
              <ButtonStyled>
                {loading && (
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: '5px' }}
                  />
                )}
                {loading && <span>Buscando Produtos</span>}
                {!loading && <span>Pesquisar</span>}
              </ButtonStyled>
            </>
          )}
          {!isRequest && !isConfirmRequest && (
            <>
              {' '}
              <button
                style={{
                  marginTop: 10,
                  marginLeft: 0,
                  padding: 10,
                  width: 200,
                  border: 0,
                  backgroundColor: '#00acc1',
                  borderRadius: 5,
                  color: '#fff',
                  fontSize: 16,
                  fontWeight: 'bold',
                  transition: '0.4s',
                  cursor: 'pointer',
                }}
                disabled={isRequest}
                onClick={confirmaPedido}
              >
                Finalizar Pedido
              </button>
            </>
          )}
        </div>
        {!isRequest && !isConfirmRequest && <Carrinho itemCart={listCart} />}
      </Form>
      {ConfirmPedido && (
        <>
          <FormAuto>
            <FinalizaPedido
              itemCart={listCart}
              Transp={autoTransp}
              Clients={autoCliente}
              Vendedores={autoVendedor}
            />
          </FormAuto>
        </>
      )}
 {!isConfirmRequest && (
        <>
          {isConfirmRequestEdit && (
             <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div className={classes.paper}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} lg={12}>
                          <div className="inputdecimal">
                            <Grid item xs={12} sm={12} lg={12}>
                              <div className="input">
                                <label>Valor unitário padrão</label>
                                <input
                                  name="valor_default_unitario"
                                  type="number"
                                  ref={register}
                                  defaultValue={valor_unitario_default}
                                  step={0.01}
                                />
                              </div>
                              <span
                                style={{
                                  overflowWrap: "break-word",
                                  fontSize: "9px",
                                  textAlign: "right",
                                }}
                              >
                                *A alteração do valor gerará impacto sobre todos
                                os produtos pesquisados, desconsiderando o filtro
                                opcional da tabela (Filtrar)
                              </span>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                      <ButtonStyled variant="contained" color="primary">
                        Alterar valor unitário
                      </ButtonStyled>
                    </Form>
                  </div>
                </Fade>
              </Modal>
            )}
  
       
       
          <DataTable
            rows={rowsList}
            rowHead={rowHead}
            title={'Produtos'}
            sort={true}
            filter ={true}
            maxHeight="76"
            titleNoData={'Pesquise os produtos'}
            addAction={[
              {
                icon: "list",
                onClick: () => {
                  setIsConfirmRequestEdit(!isConfirmRequestEdit);
                  setOpen(true);

                  const value = rowsList[0]
                    ? rowsList.slice(0, 1)[0].inputValor.props.defaultValue
                    : 0;
                  setValor_unitario_default(value);
                },
                isFreeAction: true,
                tooltip: "Alterar valor unitário",
              },
            ]}
            components={{
              Pagination: props => (
                <>
                  <Grid container spacing={0} style={{ alignItems: 'end' }}>
                    <Grid item xs={10} lg={11} md={11}>
                      <Grid container spacing={0} style={{ width: '100%' }}>
                        <Grid
                          className="index"
                          item
                          sm={12}
                          lg={12}
                          md={12}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            textAlign: 'end',
                          }}
                        >
                          <Typography variant="subtitle2">
                            Total escolhido:
                          </Typography>
                        </Grid>
                        <Grid
                          className="index"
                          item
                          sm={12}
                          lg={12}
                          md={12}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            textAlign: 'end',
                          }}
                        >
                          <Typography variant="subtitle2">Estoque:</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} lg={1} md={1}>
                      <Grid container spacing={0} style={{ width: '100%' }}>
                        <Grid
                          className="index"
                          item
                          sm={12}
                          lg={12}
                          md={12}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            textAlign: 'end',
                          }}
                        >
                          <Typography variant="subtitle2">
                            {totalEstoqueEsc.toLocaleString('pt-BR', {
                              style: 'decimal',
                              currency: 'BRL',
                              minimumFractionDigits: 2,
                            }) + ' m '}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        className="index"
                        item
                        sm={12}
                        lg={12}
                        md={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          textAlign: 'end',
                        }}
                      >
                        <Typography variant="subtitle2">
                          {totalEstoque
                            ? totalEstoque.toLocaleString('pt-BR', {
                                style: 'decimal',
                                currency: 'BRL',
                                minimumFractionDigits: 2,
                              }) + ' m '
                            : '0,00 m'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <TablePagination {...props} />
                </>
              ),
            }}
          />
        </>
      )}
      <ToastContainer />
    </>
  );
}

export default connect()(Clientes);
