import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PhotoCamera } from '@material-ui/icons';

import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button } from './styles';

import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import { API } from '../../../config/api';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {
  EmailIcon,
  WhatsappIcon,
  EmailShareButton,
  WhatsappShareButton,
} from 'react-share';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 0, 0),
    maxWidth: '550px',
    maxHeight: '650px',
    overflow: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    border: 0,
    borderRadius: '20px',
    backgroundColor: '#00acc1',
    color: '#fff',
    padding: '5px',
    cursor: 'pointer',
  },
}));

const StyledBadge = withStyles(theme => ({
  badge: {
    right: 4,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 0px',
  },
}))(Badge);

export default function ImagesProducts({
  num,
  mestre_id,
  mestre_codigo,
  type,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = useState('');
  const [imagens, setImagens] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  const perfil = sessionStorage.getItem('perfil');

  var toastId = null;

  const handlePdf = async e => {
    e.preventDefault();

    try {
      const req = await axios.get(

        `${API.relatorios}/?relatorio=ESTOQUEIMAGEM&email=${email}&produtos=${num}`,
      
        {
          

          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );

      // const fileURL = URL.createObjectURL(blob);

      var new_img = req.data.data.map(item => {
   
        return { url: item.URL, nome: item.ITEM_NOME, urlext: item.URL };
      });

      setImagens(new_img);
      setOpen(true);
    } catch (err) {
      toast.error('Nenhuma imagem encontrada.');
    }
  };

  return (
    <>
      <Button type="button" onClick={e => handlePdf(e)}>
        <PhotoCamera />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        <div className={classes.paper}>
          {
            <Carousel>
              {imagens
                ? imagens.map(image => {
                    return (
                      <div>
                        <div className="img">
                          <WhatsappShareButton
                            url={image.urlext}
                            className="m-2"
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                          <EmailShareButton url={image.urlext} className="m-2">
                            <EmailIcon size={32} round />
                          </EmailShareButton>

                          <div className="Demo__some-network__share-count">
                            &nbsp;
                          </div>
                        </div>
                        <img src={image.url} />
                        <p className="legend">{image.nome}</p>
                      </div>
                    );
                  })
                : ''}
            </Carousel>
          }
        </div>
      </Modal>
    </>
  );
}

ImagesProducts.propTypes = {
  num: PropTypes.number.isRequired,
};
