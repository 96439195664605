import React from "react";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import axios from "axios";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button } from "./styles";
import {API} from "../../../config/api"

export default function Pdf({ num }) {
  const token = sessionStorage.getItem("token");
  const email = sessionStorage.getItem("email");
  const handlePdl = async () => {
    try {
      toast.success("Aguarde seu PDF está sendo gerado.");
      const req = await axios.get(
        `${API.impressos_pedidos}/${num}/?email=${email}`,
        {
          responseType: "blob",
          headers: {
            "x-access-token": token
          }
        }
      );
      const file = new Blob([req.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (err) {
      toast.error("Não foi possível gerar seu PDF");
    }
  };
  return (
    <Button type="button" onClick={() => handlePdl()}>
      <PictureAsPdfIcon />
    </Button>
  );
}

Pdf.propTypes = {
  num: PropTypes.number.isRequired
};
