import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Autocomplete from 'react-autocomplete';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DataTable from 'components/Table/Table.js';
import ModalCreateContatos from '../ModalCreateContatos';
import EditContato from '../ModalEditContatos';
import DeleteContato from '../ModalDeleteContatos';

import { Chart } from 'react-google-charts';
import moment from 'moment';
import { API } from '../../../config/api';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Danger from 'components/Typography/Danger.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardFooter from 'components/Card/CardFooter.js';
import 'react-tabs/style/react-tabs.css';
import Icon from '@material-ui/core/Icon';



// @material-ui/icons
import Store from '@material-ui/icons/Store';
import { Form } from './styles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '80%',
    maxHeight: '80%',
    minHeight: '80%',
    minWidth: '80%',
    overflow: 'scroll',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 420,
  },
  button: {
    border: 0,
    borderRadius: '20px',
    backgroundColor: '#00acc1',
    color: '#fff',
    padding: '5px',
    cursor: 'pointer',
  },
}));

export default function ModalClientes({ data }) {
  // Token
  const token = sessionStorage.getItem('token');
  const perfil = sessionStorage.getItem('perfil');
  const email = sessionStorage.getItem('email');
  const id_erp = sessionStorage.getItem('id_erp');


  // Modal
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isopen, setisOpen] = React.useState(true);
  const datac = [{ name: 'Page A', uv: 400, pv: 2400, amt: 2400 }];


  const handleOpen = () => {
    setOpen(true);
    requisicao();
  };
  const handleClose = () => {
    setOpen(false);
  };

  function handleCloseChild() {
    handleReqContatos();
    setisOpen(false);
  }

  const {
    id,
    ativa,
    bloqueada,
    tipo,
    nome,
    apelido,
    cnpj,
    inscricaoEstadual,
    inscricaoMunicipal,
    cpf,
    rg,
    enderecoLogradouro,
    enderecoNumero,
    enderecoComplemento,
    enderecoBairro,
    enderecoCidade,
    enderecoEstado,
    enderecoPais,
    enderecoCep,
    enderecoLatitude,
    enderecoLongitude,
    cliente,
    fornecedor,
    vendedor,
    transportadora,
    funcionario,
    segmentoDescricao,
    tags,
    observacoes,
    vendedorPadrao,
  } = data;
  // react-hooks-form
  const { register, getValues } = useForm();
  // Submit
  const handlePut = async data => {
    try {
      const {
        ativa,
        bloqueada,
        tipo,
        nome,
        apelido,
        cnpj,
        inscricaoEstadual,
        inscricaoMunicipal,
        cpf,
        rg,
        enderecoLogradouro,
        enderecoNumero,
        enderecoComplemento,
        enderecoBairro,
        enderecoCidade,
        enderecoEstado,
        enderecoPais,
        enderecoCep,
        enderecoLatitude,
        enderecoLongitude,
        cliente,
        fornecedor,
        vendedor,
        transportadora,
        funcionario,
        // segmentoDescricao,
        tags,
        observacoes,
        vendedorPadraoId,
        vendedorPadraoNome,
      } = data;

      await axios.put(
        `${API.clientes}/${data.id}`,
        {
          ativa,
          bloqueada,
          tipo,
          nome,
          apelido,
          cnpj,
          inscricaoEstadual,
          inscricaoMunicipal,
          cpf,
          rg,
          enderecoLogradouro,
          enderecoNumero,
          enderecoComplemento,
          enderecoBairro,
          enderecoCidade,
          enderecoEstado,
          enderecoPais,
          enderecoCep,
          enderecoLatitude,
          enderecoLongitude,
          cliente,
          fornecedor,
          vendedor,
          transportadora,
          funcionario,
          segmentoDescricao: null,
          tags,
          observacoes,
          vendedorPadrao: {
            id: vendedorPadraoId,
            nome: vendedorPadraoNome,
          },
        },
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
    } catch (err) {
      toast.error('Erro ao atualizar.');
    }
  };

  // AutoComplete
  const [auto, setAuto] = useState();
  let value = vendedorPadrao === null ? '' : vendedorPadrao.nome;
  let valueId = vendedorPadrao === null ? '' : vendedorPadrao.id;
  const [valueAutoId, setValueAutoId] = useState(valueId);
  const [valueAutoNome, setValueAutoNome] = useState(value);
  const [contatos, setContatos] = useState([]);
  const [infoComerciais, setInfoComerciais] = useState([]);
  const [limite, setLimite] = useState('');
  const [reserva, setReserva] = useState('');
  const [limite_credito_saldo, setLimite_credito_saldo] = useState(0);
  const [limite_reserva_saldo, setLimite_reserva_saldo] = useState(0);
  const [pagamentosEfetuados, setPagamentosEfetuados] = useState('');
  const [titulosVencidos, setTituloVencidos] = useState('');
  const [titulosaVencer, setTituloaVencer] = useState('');
  const [prazo_medio, setPrazoMedio] = useState(0);
  const [atraso, setAtraso] = useState('');

  const [compras, setCompras] = useState('');
  const [meta, setMeta] = useState(0);





    async function getmeta() {
      try {
        const response = await axios.get(
          `${API.getMetas}?email=${email}&id_erp=${id_erp}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        console.log(response);
        setMeta(response.data.data);

      } catch (err) {
        // toast.error('Erro ao carregar as metas para o usuário');
      }
    }

    const requisicao = async () => {
      try {
        const response = await axios.get(`${API.vendedores}?email=${email}`, {
          headers: {
            'x-access-token': token,
          },
        });

        const list = response.data.data;
        setAuto(list);
      } catch (err) {
        toast.error('Houve um erro ao listar vendedores.');
      }
    };

    async function handleReqContatos() {
      try {
        const response2 = await axios.get(`${API.clientes}/${data.id}/contatos`, {
          headers: {
            'x-access-token': token,
          },
        });

        const contact = response2.data.data;

        setContatos(contact);
      } catch (err) {
        //  toast.error("Houve um erro ao listar contatos.");
      }
    }
    /*
      useEffect(() => {
        handleReqContatos();
      }, [isopen]);
    */
    async function handleReqInfoComerciais() {
      try {
        const response = await axios.get(
          `${API.infocomerciais}?conta_cliente=${data.id}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );

        const dadoscomerciais = response.data.data;

        setInfoComerciais(dadoscomerciais[0]);

        let primeira_compra_valor = dadoscomerciais[0].IC_PRIMEIRA_COMPRA_VALOR.toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
          },
        );

        let ultima_compra_valor = dadoscomerciais[0].IC_ULTIMA_COMPRA_VALOR.toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
          },
        );

        let maior_compra_valor = dadoscomerciais[0].IC_MAIOR_COMPRA_VALOR.toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
          },
        );

        let primeira_compra_data = moment(
          dadoscomerciais[0].IC_PRIMEIRA_COMPRA_DATA,
        ).format('DD/MM/YYYY');

        let ultima_compra_data = moment(
          dadoscomerciais[0].IC_ULTIMA_COMPRA_DATA,
        ).format('DD/MM/YYYY');

        let maior_compra_data = moment(
          dadoscomerciais[0].IC_MAIOR_COMPRA_DATA,
        ).format('DD/MM/YYYY');

        setPrazoMedio([
          [
            'Element',
            'Dias',
            { role: 'style' },
            {
              sourceColumn: 0,
              role: 'annotation',
              type: 'string',
              calc: 'stringify',
            },
          ],
          [
            'Prazo Médio',
            Number(dadoscomerciais[0].IC_PRAZO_MEDIO),
            'blue',
            Number(dadoscomerciais[0].IC_PRAZO_MEDIO) + ' DIAS',
          ],
          ['', null, 'red', null],
        ]);

        setLimite_credito_saldo(
          Number(dadoscomerciais[0].IC_CREDITO_DISPONIVEL).toLocaleString(
            'pt-BR',
            {
              style: 'currency',
              currency: 'BRL',
            },
          ),
        );
        setLimite_reserva_saldo(
          Number(dadoscomerciais[0].IC_RESERVA_DISPONIVEL).toLocaleString(
            'pt-BR',
            {
              style: 'currency',
              currency: 'BRL',
            },
          ),
        );

        //Dados do gráfico
        setLimite([
          [
            'Element',
            'Valor',
            { role: 'style' },
            {
              sourceColumn: 0,
              role: 'annotation',
              type: 'string',
              calc: 'stringify',
            },
          ],
          [
            'Limite',
            Number(dadoscomerciais[0].IC_CREDITO_LIMITE),
            '#b87333',
            Number(dadoscomerciais[0].IC_CREDITO_LIMITE).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
          ],
          [
            'Utilizado',
            Number(dadoscomerciais[0].IC_CREDITO_UTILIZADO),
            'silver',
            Number(dadoscomerciais[0].IC_CREDITO_UTILIZADO).toLocaleString(
              'pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              },
            ),
          ],
        ]);

        setAtraso([
          [
            'Element',
            'Dias',
            { role: 'style' },
            {
              sourceColumn: 0,
              role: 'annotation',
              type: 'string',
              calc: 'stringify',
            },
          ],
          [
            'Atraso Médio',
            Number(dadoscomerciais[0].PE_ATRASO_MEDIO),
            'blue',
            Number(dadoscomerciais[0].PE_ATRASO_MEDIO),
          ],
          [
            'Maior Atraso',
            Number(dadoscomerciais[0].PE_MAIOR_ATRASO),
            'red',
            Number(dadoscomerciais[0].PE_MAIOR_ATRASO),
          ],
        ]);

        setReserva([
          [
            'Element',
            'Valor',
            { role: 'style' },
            {
              sourceColumn: 0,
              role: 'annotation',
              type: 'string',
              calc: 'stringify',
            },
          ],
          [
            'Limite',
            Number(dadoscomerciais[0].IC_RESERVA_LIMITE),
            '#b87333',
            Number(dadoscomerciais[0].IC_RESERVA_LIMITE).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
          ],
          [
            'Utilizado',
            Number(dadoscomerciais[0].IC_RESERVA_UTILIZADA),
            'red',
            Number(dadoscomerciais[0].IC_RESERVA_UTILIZADA).toLocaleString(
              'pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              },
            ),
          ],
        ]);
        setPagamentosEfetuados([
          ['Pagamentos efetuados', 'Valor R$', 'Títulos'],
          [
            'Em dia',
            Number(dadoscomerciais[0].PE_EM_DIA_VALOR).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            Number(dadoscomerciais[0].PE_EM_DIA_TITULOS),
          ],
          [
            'Após  o vencimento',
            Number(dadoscomerciais[0].PE_APOS_VENCIMENTO_VALOR).toLocaleString(
              'pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              },
            ),
            Number(dadoscomerciais[0].PE_APOS_VENCIMENTO_TITULOS),
          ],
          [
            'Em cartório',
            Number(dadoscomerciais[0].PE_CARTORIO_VALOR).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            Number(dadoscomerciais[0].PE_CARTORIO_TITULOS),
          ],
          [
            'Em protesto',
            Number(dadoscomerciais[0].PE_PROTESTO_VALOR).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            Number(dadoscomerciais[0].PE_PROTESTO_TITULOS),
          ],
        ]);

        setTituloVencidos([
          ['Títulos vencidos', 'Valor R$', 'Títulos'],
          [
            'Títulos vencidos - Total',
            Number(dadoscomerciais[0].VI_TOTAL).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            Number(dadoscomerciais[0].VI_TITULOS),
          ],
          [
            'Títulos vencidos até 7 dias',
            Number(dadoscomerciais[0].VI_ATE_07_DIAS_VALOR).toLocaleString(
              'pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              },
            ),
            Number(dadoscomerciais[0].VI_ATE_07_DIAS_TITULOS),
          ],
          [
            'Títulos vencidos a mais de 7 dias',
            Number(dadoscomerciais[0].VI_ACIMA_07_DIAS_VALOR).toLocaleString(
              'pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              },
            ),
            Number(dadoscomerciais[0].VI_ACIMA_07_DIAS_TITULOS),
          ],
          [
            'Em cartório',
            Number(dadoscomerciais[0].VI_CARTORIO_VALOR).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            Number(dadoscomerciais[0].VI_CARTORIO_TITULOS),
          ],
          [
            'Em protesto',
            Number(dadoscomerciais[0].VI_PROTESTO_VALOR).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            Number(dadoscomerciais[0].VI_PROTESTO_TITULOS),
          ],
        ]);

        setTituloaVencer([
          ['Títulos a vencer', 'Valor R$', 'Títulos'],
          [
            'Títulos a vencer - Total',
            Number(dadoscomerciais[0].VE_TOTAL).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            Number(dadoscomerciais[0].VE_TITULOS),
          ],
          [
            'Títulos a vencer em até 30 dias',
            Number(dadoscomerciais[0].VE_ATE_30_DIAS_VALOR).toLocaleString(
              'pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              },
            ),
            Number(dadoscomerciais[0].VE_ATE_30_DIAS_TITULOS),
          ],
          [
            'Títulos a vencer entre 30 e 60 dias',
            Number(dadoscomerciais[0].VE_30_60_DIAS_VALOR).toLocaleString(
              'pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              },
            ),
            Number(dadoscomerciais[0].VE_30_60_DIAS_TITULOS),
          ],
          [
            'Títulos a vencer entre 60 e 90 dias',
            Number(dadoscomerciais[0].VE_60_90_DIAS_VALOR).toLocaleString(
              'pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              },
            ),
            Number(dadoscomerciais[0].VE_60_90_DIAS_TITULOS),
          ],
          [
            'Títulos a vencer acima de 90 dias',
            Number(dadoscomerciais[0].VE_ACIMA_90_DIAS_VALOR).toLocaleString(
              'pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              },
            ),
            Number(dadoscomerciais[0].VE_ACIMA_90_DIAS_TITULOS),
          ],
        ]);

        setCompras([
          ['id', 'childLabel', 'parent', 'size', { role: 'style' }],
          [0, 'Compras', -1, 2, 'black'],
          [1, 'Primeira', 0, 3, 'black'],
          [2, 'Última', 0, 5, 'black'],
          [3, 'Maior', 0, 1, 'black'],
          [4, `Data:${primeira_compra_data}`, 1, 2, 'black'],
          [5, `Valor: ${primeira_compra_valor}`, 1, 2, 'black'],
          [6, `Data:${ultima_compra_data}`, 2, 2, 'black'],
          [7, `Valor:${ultima_compra_valor}`, 2, 2, 'black'],
          [8, `Data:${maior_compra_data}`, 3, 2, 'black'],
          [9, `Valor:${maior_compra_valor}`, 3, 2, 'black'],
        ]);

        //   setContatos(contact);
      } catch (err) {
        //  toast.error("Houve um erro ao listar contatos.");
      }
    }

    //  useEffect(() => {
    //   handleReqInfoComerciais();
    // }, [isopeninfo]);



  function createData(
    tipo,
    descricao,
    complemento
  ) {
    return {
      tipo,
      descricao,
      complemento
    };
  }


  const rowHead = [
    {
      title: 'Tipo',
      field: 'tipo',
      cellStyle: {
        fontSize: "12px"
      }
    },
    {
      title: 'Descrição',
      field: 'descricao',
      cellStyle: {
        fontSize: "12px"
      }
    },
    {
      title: 'Complemento',
      field: 'complemento',
      cellStyle: {
        fontSize: "12px"
      }
    }
  ];

  var rowContatos = contatos
    ? contatos.map(item => {
      const { tipo, descricao, complemento } = item;
      const row = createData(tipo,
        descricao,
        complemento
      )

      return row;
    })
    : [{ error: 'Não encontrado' }];





  const [logradouroApi, setLogradouro] = useState(enderecoLogradouro);
  const [cidadeApi, setCidade] = useState(enderecoCidade);
  const [bairroApi, setBairro] = useState(enderecoBairro);
  const [estadoApi, setEstado] = useState(enderecoEstado);
  const [complementoApi, setComplemento] = useState(enderecoComplemento);
  // Cep
  const handleCep = async e => {
    try {
      const response = await axios.get(
        `https://viacep.com.br/ws/${e.target.value}/json`,
      );
      const {
        logradouro: logradouroApi,
        bairro: bairroApi,
        uf: ufApi,
        localidade: localidadeApi,
        complemento: complementoApi,
      } = response.data;

      setLogradouro(logradouroApi);
      setBairro(bairroApi);
      setEstado(ufApi);
      setCidade(localidadeApi);
      setComplemento(complementoApi);
    } catch (err) {
      toast.error('Cep inválido.');
    }
  };

  // Selects
  return (
    <div>
      <button className={classes.button} type="button" onClick={handleOpen}>
        <EditIcon />
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Tabs>
              <TabList>
                <Tab>Conta</Tab>

                <Tab onClick={() => handleReqContatos()}>Contatos</Tab>
                <Tab onClick={() => handleReqInfoComerciais()}>
                  Informações comerciais e financeiras
                </Tab>
              </TabList>

              <TabPanel>
                <Form>
                  <Grid container spacing={1}>
                    <input
                      type="hidden"
                      ref={register}
                      defaultValue={id}
                      name="id"
                    />

                    <Grid item xs={6} sm={6} lg={3}>
                      <div className="divCheck">
                        <h6>Ativa</h6>
                        <input
                          type="checkbox"
                          name="ativa"
                          ref={register}
                          defaultChecked={ativa}
                          value={true}
                          readyonly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={6} lg={4}>
                      <div className="divCheck">
                        <h6>Bloqueada</h6>
                        <input
                          type="checkbox"
                          name="bloqueada"
                          ref={register}
                          value={bloqueada}
                          defaultChecked={bloqueada}
                          readonly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="divCheck">
                        <h6>Cliente</h6>
                        <input
                          type="checkbox"
                          name="cliente"
                          ref={register}
                          defaultChecked={cliente}
                          value={true}
                          readyonly
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Tipo</label>
                        <select
                          name="tipo"
                          ref={register}
                          defaultValue={tipo}
                          disabled
                        >
                          <option value={'JURIDICA'}>JURIDICO</option>
                          <option value={'FISICA'}>FISICA</option>
                        </select>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Nome</label>
                        <input
                          name="nome"
                          type="text"
                          ref={register}
                          defaultValue={nome}
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Apelido</label>
                        <input
                          name="apelido"
                          type="text"
                          ref={register}
                          defaultValue={apelido}
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Cnpj</label>
                        <input
                          name="cnpj"
                          type="text"
                          ref={register}
                          defaultValue={cnpj}
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Inscrição Estadual</label>

                        <input
                          name="inscricaoEstadual"
                          type="text"
                          placeholder="999.999.999.999"
                          ref={register}
                          defaultValue={inscricaoEstadual}
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Inscrição Municipal</label>
                        <input
                          name="inscricaoMunicipal"
                          type="text"
                          ref={register}
                          defaultValue={inscricaoMunicipal}
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Cpf</label>
                        <input
                          name="cpf"
                          type="text"
                          ref={register}
                          defaultValue={cpf}
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>RG</label>

                        <input
                          name="rg"
                          type="text"
                          ref={register}
                          defaultValue={rg}
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Cep</label>
                        <input
                          name="enderecoCep"
                          type="text"
                          onBlur={handleCep}
                          ref={register}
                          defaultValue={enderecoCep}
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Endereço</label>
                        <input
                          name="enderecoLogradouro"
                          type="text"
                          value={logradouroApi}
                          onChange={e => setLogradouro(e.target.value)}
                          ref={register}
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Número</label>
                        <input
                          name="enderecoNumero"
                          type="text"
                          ref={register}
                          defaultValue={enderecoNumero}
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Complemento</label>
                        <input
                          name="enderecoComplemento"
                          type="text"
                          value={complementoApi ? complementoApi : ''}
                          onChange={e => setComplemento(e.target.value)}
                          ref={register}
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Bairro</label>
                        <input
                          name="enderecoBairro"
                          type="text"
                          value={bairroApi ? bairroApi : ''}
                          onChange={e => setBairro(e.target.value)}
                          ref={register}
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Cidade</label>
                        <input
                          name="enderecoCidade"
                          type="text"
                          value={cidadeApi ? cidadeApi : ''}
                          onChange={e => setCidade(e.target.value)}
                          ref={register}
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>Estado</label>
                        <input
                          name="enderecoEstado"
                          type="text"
                          value={estadoApi ? estadoApi : ''}
                          onChange={e => setEstado(e.target.value)}
                          ref={register}
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                      <div className="input">
                        <label>País</label>
                        <input
                          name="enderecoPais"
                          type="text"
                          ref={register}
                          defaultValue={enderecoPais}
                          readOnly
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={8}>
                      <div className="input">
                        <label>Vendedor</label>
                        <input
                          name="vendedorPadraoId"
                          type="hidden"
                          ref={register}
                          defaultValue={valueAutoId}
                        />
                        <input
                          name="vendedorPadraoNome"
                          type="hidden"
                          ref={register}
                          defaultValue={valueAutoNome}
                        />
                        <Autocomplete
                          items={auto}
                          shouldItemRender={(item, value) =>
                            item.NOME.toLowerCase().indexOf(
                              value.toLowerCase(),
                            ) > -1
                          }
                          getItemValue={item => {
                            setValueAutoId(item ? item.NUMCAD1 : '');
                            return item ? item.NOME : '';
                          }}
                          renderItem={(item, isHighlighted) => (
                            <div
                              key={item.NUMCAD1}
                              style={{
                                background: isHighlighted
                                  ? 'lightgray'
                                  : 'white',
                                width: '100%',
                                zIndex: '100',
                              }}
                            >
                              {item.NOME}
                            </div>
                          )}
                          value={valueAutoNome ? valueAutoNome : ''}
                          onChange={e => setValueAutoNome(e.target.value)}
                          onSelect={val => setValueAutoNome(val)}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                      <div className="input">
                        <label>Observações</label>
                        <textarea
                          label="Observações"
                          id="observacoes"
                          name="observacoes"
                          multiline
                          rows={5}
                          type="text"
                          value={observacoes ? observacoes : ''}
                          ref={register}
                          readOnly

                          style={{ width: '100%' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        /></div>
                    </Grid>
                  </Grid>
                </Form>
              </TabPanel>
              <TabPanel>
                <ModalCreateContatos
                  data={data}
                  onchangechild={handleCloseChild}
                />
                <DataTable
                  rows={rowContatos}
                  rowHead={rowHead}
                  title={'Contatos'}
                />
              </TabPanel>
              <TabPanel>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <CardHeader color="warning" stats icon>
                        <CardIcon color="warning">
                          <Icon>trending_up</Icon>
                        </CardIcon>
                        <p
                          className={classes.cardCategory}
                          style={{
                            color: 'black',
                          }}
                        >
                          Limite de crédito (Saldo)
                        </p>
                        <h3
                          className={classes.cardTitle}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            color: 'black',
                          }}
                        >
                          {limite_credito_saldo}
                        </h3>
                      </CardHeader>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          <Danger></Danger>
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <CardHeader color="success" stats icon>
                        <CardIcon color="success">
                          <Store />
                        </CardIcon>
                        <p
                          className={classes.cardCategory}
                          style={{
                            color: 'black',
                          }}
                        >
                          Reserva Disponível (Saldo)
                        </p>
                        <h3
                          className={classes.cardTitle}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            color: 'black',
                          }}
                        >
                          {limite_reserva_saldo}
                        </h3>
                      </CardHeader>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          <Danger></Danger>
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={auto}
                        height={'250px'}
                        chartType="BarChart"
                        loader={<div>Carregando gráfico</div>}
                        data={limite}
                        options={{
                          is3D: true,

                          title: 'Limite de crédito',
                          titlePosition: 'out',
                          axisTitlesPosition: 'out',
                          hAxis: { textPosition: 'out' },
                          vAxis: { textPosition: 'out' },

                          width: auto,
                          height: auto,
                          bar: { groupWidth: '45%' },
                          legend: { position: 'none' },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '6' }}
                      />
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={'auto'}
                        height={'250px'}
                        chartType="BarChart"
                        loader={<div>Carregando gráfico</div>}
                        data={reserva}
                        options={{
                          is3D: true,

                          title: 'Limite de reserva',
                          width: auto,
                          height: auto,
                          bar: { groupWidth: '45%' },
                          legend: { position: 'none' },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '6' }}
                      />
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={'auto'}
                        height={'250px'}
                        chartType="WordTree"
                        loader={<div>Carregando gráfico</div>}
                        data={compras}
                        options={{
                          maxFontSize: 12,
                          enableInteractivity: false,
                          tooltip: { trigger: 'none' },
                          chartArea: { width: '100%' },
                          width: auto,
                          height: auto,
                          colors: ['black', 'black', 'black'],

                          wordtree: {
                            format: 'explicit',
                            type: 'suffix',
                          },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                      />
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={'auto'}
                        height={'250px'}
                        chartType="BarChart"
                        loader={<div>Carregando gráfico</div>}
                        data={prazo_medio}
                        options={{
                          is3D: true,
                          title: 'Prazo Médio das compras',
                          width: auto,
                          height: auto,
                          bar: { groupWidth: '65%' },
                          legend: { position: 'none' },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '6' }}
                      />
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <CardHeader color="success" stats icon>
                        <CardIcon color="success">
                          <Icon>attach_money</Icon>
                        </CardIcon>
                        <p
                          className={classes.cardCategory}
                          style={{
                            color: 'black',
                          }}
                        >
                          Informações Financeiras
                        </p>
                        <h3
                          className={classes.cardTitle}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            color: 'black',
                          }}
                        ></h3>
                      </CardHeader>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          <Danger></Danger>
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={'auto'}
                        height={'300px'}
                        chartType="Table"
                        loader={<div>Carregando gráfico</div>}
                        data={pagamentosEfetuados}
                        formatters={[
                          {
                            type: 'BarFormat',
                            column: 1,
                            options: {
                              width: 120,
                            },
                          },
                          {
                            type: 'ArrowFormat',
                            column: 2,
                          },
                        ]}
                        options={{
                          allowHtml: true,
                          showRowNumber: true,
                          width: '100%',
                          height: '100%',
                        }}
                        rootProps={{ 'data-testid': '2' }}
                      />
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={auto}
                        height={'300px'}
                        chartType="BarChart"
                        loader={<div>Carregando gráfico</div>}
                        data={atraso}
                        options={{
                          is3D: true,

                          title: 'Atrasos',
                          titlePosition: 'out',
                          axisTitlesPosition: 'out',
                          hAxis: { textPosition: 'out' },
                          vAxis: { textPosition: 'out' },

                          width: auto,
                          height: auto,
                          bar: { groupWidth: '45%' },
                          legend: { position: 'none' },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '6' }}
                      />
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={'auto'}
                        height={'300px'}
                        chartType="Table"
                        loader={<div>Carregando gráfico</div>}
                        data={titulosVencidos}
                        formatters={[
                          {
                            type: 'BarFormat',
                            column: 1,
                            options: {
                              width: 120,
                            },
                          },
                          {
                            type: 'ArrowFormat',
                            column: 2,
                          },
                        ]}
                        options={{
                          allowHtml: true,
                          showRowNumber: true,
                          width: '100%',
                          height: '100%',
                        }}
                        rootProps={{ 'data-testid': '2' }}
                      />
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={'auto'}
                        height={'300px'}
                        chartType="Table"
                        loader={<div>Carregando gráfico</div>}
                        data={titulosaVencer}
                        formatters={[
                          {
                            type: 'BarFormat',
                            column: 1,
                            options: {
                              width: 120,
                            },
                          },
                          {
                            type: 'ArrowFormat',
                            column: 2,
                          },
                        ]}
                        options={{
                          allowHtml: true,
                          showRowNumber: true,
                          width: '100%',
                          height: '100%',
                        }}
                        rootProps={{ 'data-testid': '2' }}
                      />
                    </Card>
                  </GridItem>
                </GridContainer>
              </TabPanel>
            </Tabs>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ModalClientes.propTypes = {
  data: PropTypes.object.isRequired,
};
